import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetConFileGetTipoContenuto, apiGetConFile, apiPostConFileAdd, apiPostConFileUpdate, apiPostConFileUpload } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import PlaylistItem from './PlaylistItem';
import GestioneContenutiTree from './GestioneContenutiTree';

export default function GestioneContenutiFileScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Contenuti', link:'/contenuti' },
    { title: 'Crea/Modifica File', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tipiContenuto, setTipiContenuto] = useState([]);
  const [inputFile, setInputFile] = useState(null);
  const [pageAction, setPageAction] = useState("new");
  const [selectedFolderId, setSelectedFolderId] = useState(1);
 
  //FORM
  const [formDescStatus, setFormDescStatus] = useState('')
  const [formTipoFileStatus, setFormTipoFileStatus] = useState('')
  const [formUploadStatus, setFormUploadStatus] = useState('')
  const formDescInput = React.useRef();
  const formPathFileInput = React.useRef();
  const formTipoFileSelect = React.useRef();
  const formCodeInput = React.useRef();
  const formUploadInput = React.useRef();

  const pSize  = {
    width: 640,
    height: 360
  }; 

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {
    
  
  }, [selectedFolderId])


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    apiGetConFileGetTipoContenuto(function(response) {
        let tipiList = [];
        if (response!=null && response.status>=200 && response.status<400) {
            tipiList = response.data; 
        }
        setTipiContenuto(tipiList);
        loadFile();
    });
  }

  function handleDescrizioneField(event) {
    let value = event.target.value;
    selectedFile.descFile = value;
    let newObj = Object.assign({}, selectedFile);
    setSelectedFile(newObj); 
  }

  function handleTipoContenutoField(event) {
    let value = event.target.value;
    selectedFile.tipoContenuto = value;
    let newObj = Object.assign({}, selectedFile);
    setSelectedFile(newObj); 
  }

  function loadFile() {
    const  url = window.location.pathname;
    const idFile = getValueByQueryString("idfile");
    
    
    if (idFile!==null && idFile!=="") {
        //edit
        setPageAction("edit");
        apiGetConFile(idFile, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setSelectedFile(response.data); 
                setSelectedFolderId(response.data.idFolder); 
                console.log("setSelectedFile", response.data);
            }
        });
    }
    else {
        //new
        setPageAction("new");
        let idFolder = getValueByQueryString("folder");
        if (idFolder=="undefined" || idFolder==null) {
            idFolder = 1;
        }
        let idGruppo = 0;
        let userAnag = getUserAnag();
        if (userAnag!=null) {
            idGruppo = userAnag.idGruppo;
        }
        let newFile = {
          idGruppoUtente: idGruppo,
          descFile: "nuovo",
          tipoContenuto: 0,
          sysUser: "",
          sysDate: new Date().toISOString(),
          sysAttivo: true,
          idFolder: idFolder
        };
        setSelectedFile(newFile);
        setSelectedFolderId(idFolder); 
        console.log("setSelectedFile", setSelectedFile);
    }
  }

  function onFileChange(event) {
    let inpFile = event.target.files[0];
    setInputFile(inpFile);
    console.log(inpFile);
  }



  function validateForm() {
    console.log("validateForm");
    let valid = true;

    const desc = formDescInput.current.value;
    const tipoFile = formTipoFileSelect.current.value;
    
    
    if (desc==null || desc=="") {
        valid = false;
        setFormDescStatus(" notvalid");
    }
    else {
        setFormDescStatus("");
    }

    if (tipoFile==null || tipoFile=="") {
        valid = false;
        setFormTipoFileStatus(" notvalid");
    }
    else {
        setFormTipoFileStatus("");
    }

    if (pageAction=="new") {
        if (inputFile==null || inputFile=="") {
            valid = false;
            setFormUploadStatus(" notvalid");
        }
        else {
            setFormUploadStatus("");
        }    
    }


    return valid;    
} 

function saveFile() {
    if (selectedFile!=null) {

        if (pageAction=="new") {
            apiPostConFileAdd(selectedFile, function(response) {
                if (response!=null && response.status>=200 && response.status<400) {
                    let newFile = response.data;
                    selectedFile.idFile = newFile.idFile;
                    selectedFile.codiceFile = newFile.codiceFile;
                    setSelectedFile(newFile);
                    //upload
                    uploadFile();
                } 
                else {
                    setAlertSuccessOpen(false);
                    setAlertErrorOpen(true);
                }                               
            });
        }
        else 
        if (pageAction=="edit") {
            apiPostConFileUpdate(selectedFile, function(response) {
                if (response!=null && response.status>=200 && response.status<400) {
                    let newFile = response.data;
                    setSelectedFile(newFile);
                    if (inputFile!=null) {
                        //upload
                        uploadFile();
                    }
                    else {
                        setAlertSuccessOpen(true);
                        setAlertErrorOpen(false);
                    }
                }      
                else {
                    setAlertSuccessOpen(false);
                    setAlertErrorOpen(true);
                }

            });
        }
    }
    else {
        console.log("Selected file is null");
    }

}

function updateFileAttivo() {
    if (selectedFile!=null) {

        selectedFile.sysAttivo = true; 
        apiPostConFileUpdate(selectedFile, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
            }      
            else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
            }

        });
    }

}

function uploadFile() {
    let idUtente = 0;
    let userAnag = getUserAnag();
    if (userAnag!=null) {
        idUtente = userAnag.idutente;
    }
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "file",
        inputFile,
        inputFile.name
    );

    apiPostConFileUpload(selectedFile.idFile, selectedFile.idFolder, idUtente, formData, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
            navigate("/contenuti?folder"+response.data.idFolder);
          }
          else {
            setAlertSuccessOpen(false);
            setAlertErrorOpen(true);
          }
    });
}

function folderActionCallback(action, folderId) {
    console.log("folderActionCallback="+action+"  folder="+folderId);
    if (action!=null) {
      if (action=="select") {
        setSelectedFolderId(folderId);
      }
    }
  }

  function footerCancelCallback() {
    console.log("cancel");
    if (selectedFolderId!=null) {
        navigate("/contenuti?folder"+selectedFolderId);
    }
    else {
        navigate("/contenuti");
    }
    
  }

  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
        const desc = formDescInput.current.value;
        const tipoFile = formTipoFileSelect.current.value;
        selectedFile.descFile = desc;
        selectedFile.tipoContenuto = tipoFile;
        selectedFile.idFolder=selectedFolderId;
        setSelectedFile(selectedFile);

        saveFile();
    }
  }  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione File
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-desc" className='required' >Descrizione</label><br/>
                            <input id="avp-form-desc" ref={formDescInput} className={formDescStatus} style={{ "maxWidth":"none"}} type='text' value={selectedFile!=null ? selectedFile.descFile : ''} onChange={handleDescrizioneField}  />
                        </div>  
                    </div>
                    {
                        pageAction!=null && pageAction!="new" ?
                        (
                            <div className="avp-cms-screen-content-row clearfix">
                                <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                    <label htmlFor="avp-form-pathfile"  >Nome file</label><br/>
                                    <input id="avp-form-pathfile" ref={formPathFileInput} style={{ "maxWidth":"none"}}   type='text' value={selectedFile!=null ? selectedFile.pathFile : ''} disabled={true}  />
                                </div>  
                            </div>   
                        )
                        :
                        (
                            <div className="avp-cms-screen-content-row clearfix"></div>
                        )
                    }
   
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-tipofile" className={"required "+formTipoFileStatus}>Tipo Contenuto</label><br/>
                            <select ref={formTipoFileSelect} id="avp-form-tipofile" style={{ "maxWidth":"none"}} className={formTipoFileStatus}  value={selectedFile!=null ? selectedFile.tipoContenuto : ""} onChange={handleTipoContenutoField}>
                                <option key={-1} value=""  >Selezionare...</option>
                                { 
                                    tipiContenuto!=null && tipiContenuto.length>0 ?
                                    tipiContenuto.map((item, i) => { 
                                        return (<option key={item.idTipoFile} value={item.idTipoFile}  >{item.descTipoFile}</option>) 
                                    })
                                    : (<option></option>)
                                    
                                    
                                }  
                            </select> 
                        </div>  
                    </div>  
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-upload" className='required' >Upload file</label><br/>
                            <input id="avp-form-upload" ref={formUploadInput} style={{ "maxWidth":"none"}} className={formUploadStatus}  type="file" onChange={onFileChange}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-tipofile" className={"required "}>Cartella padre</label><br/>
                            <GestioneContenutiTree actionCallback={folderActionCallback} showToolbar={false} initialFolder={selectedFolderId!=null ? selectedFolderId : 1} showInitialFolder={true} autoExpandToSelection={true} showBin={false}  />
                        </div>  
                    </div>                                                                             
                </div>
                <div className='avp-cms-screen-content-col50' >
                    <PlaylistItem item={selectedFile} loop={false} size={pSize} />
                </div>                
            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
    </div>     
  ); 

} 