import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function AvpSaveFooter({cancelCallback, saveCallback}) {  


  function handleSave() {
    if (saveCallback!=null) {
      saveCallback();
    }
  } 
  function handleCancel() {
    if (cancelCallback!=null) {
      cancelCallback();
    }
  } 

    return (
      <div id="avp-footer-save-container" className="clearfix" >
        <div id="avp-footer-save-container-left">
          <div id="avp-footer-save-title">
              Cliccando su Salva
          </div>
          <div id="avp-footer-save-subtitle">
              confermi le modifiche apportate
          </div>
        </div> 
        
        <div id="avp-footer-save-container-right">
          <div id="avp-footer-save-submit" > 
            <button  onClick={()=>handleSave()} >SALVA</button>
          </div>   
          <div id="avp-footer-save-cancel"   >
            <button onClick={()=>handleCancel()} >ANNULLA</button>
          </div>
        </div>

      </div>

    ); 
  
  } 