import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetSysSetting, apiPostSysSetting } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


export default function GestioneSistemaDetailScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Sistema', link:'/sistema' },
    { title: 'Crea/Modifica Impostazione', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [pageAction, setPageAction] = useState("new");

 
  //FORM
  const [formIdStatus, setFormIdStatus] = useState('');
  const [formChiaveStatus, setFormChiaveStatus] = useState('');
  const [formValoreStatus, setFormValoreStatus] = useState('');
  const [formGruppoStatus, setFormGruppoStatus] = useState('');

  const formIdInput = React.useRef();
  const formChiaveInput = React.useRef();
  const formValoreInput = React.useRef();
  const formGruppoInput = React.useRef();


  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    loadSetting();
    
  }

  function loadSetting() {
    const  url = window.location.pathname;
    const idSetting = url.substring(url.lastIndexOf('/') + 1);

    if (idSetting!==null && idSetting!=="" && idSetting!="-1") {
        //edit
        setPageAction("edit");
        
        apiGetSysSetting(idSetting, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setSelectedSetting(response.data);
            }
        });
    }
    else {
        //new
        setPageAction("new");
        let newGroup = {
            idSetting: 0,
            chiave: "",
            valore: "",
            settingGroup: ""
        };
        setSelectedSetting(newGroup); 
    }
  }


  function handleIdField(event) {
    let value = event.target.value;
    selectedSetting.idSetting = eval(value);
    let newObj = Object.assign({}, selectedSetting);
    setSelectedSetting(newObj); 
  }

  function handleChiaveField(event) {
    let value = event.target.value;
    selectedSetting.chiave = value;
    let newObj = Object.assign({}, selectedSetting);
    setSelectedSetting(newObj); 
  }

  function handleValoreField(event) {
    let value = event.target.value;
    selectedSetting.valore = value;
    let newObj = Object.assign({}, selectedSetting);
    setSelectedSetting(newObj); 
  }

  function handleGruppoField(event) {
    let value = event.target.value;
    selectedSetting.settingGroup = value;
    let newObj = Object.assign({}, selectedSetting);
    setSelectedSetting(newObj); 
  }


  function validateForm() {
    console.log("validateForm");
    let valid = true;

    const idSetting = formIdInput.current.value;
    const chiave = formChiaveInput.current.value;
    const valore = formValoreInput.current.value;
    const gruppo = formGruppoInput.current.value;

    
    if (idSetting==null || idSetting=="") {
        valid = false;
        setFormIdStatus(" notvalid");
    }
    else {
        setFormIdStatus("");
    }

    if (chiave==null || chiave=="") {
        valid = false;
        setFormChiaveStatus(" notvalid");
    }
    else {
        setFormChiaveStatus("");
    }

    if (valore==null || valore=="") {
        valid = false;
        setFormValoreStatus(" notvalid");
    }
    else {
        setFormValoreStatus("");
    }   

    if (gruppo==null || gruppo=="") {
        valid = false;
        setFormGruppoStatus(" notvalid");
    }
    else {
        setFormGruppoStatus("");
    }     
    
    return valid;    
} 

function saveSetting() {
    if (selectedSetting!=null) {
        
        apiPostSysSetting(selectedSetting, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
                navigate("/sistema");
            } 
            else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
            } 
        });
    }
    else {
        console.log("Selected item is null");
    }

}


function footerCancelCallback() {
    console.log("cancel");
    navigate("/sistema");
}

function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
      saveSetting();
    }
}  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione Impostazione di sistema
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-nome" className='required' >Id</label><br/>
                            <input id="avp-form-nome" ref={formIdInput} className={formIdStatus} style={{ "maxWidth":"none"}} type='number' min={0} value={selectedSetting!=null ? selectedSetting.idSetting : ''} onChange={handleIdField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-cognome" className='required' >Chiave</label><br/>
                            <input id="avp-form-cognome" ref={formChiaveInput} className={formChiaveStatus} style={{ "maxWidth":"none"}} type='text' value={selectedSetting!=null ? selectedSetting.chiave : ''} onChange={handleChiaveField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-rifamm" className='required' >Valore</label><br/>
                            <input id="avp-form-rifamm" ref={formValoreInput} className={formValoreStatus} style={{ "maxWidth":"none"}} type='text' value={selectedSetting!=null ? selectedSetting.valore : ''} onChange={handleValoreField}  />
                        </div>  
                    </div>                    
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-email" className='required' >Gruppo</label><br/>
                            <input id="avp-form-email" ref={formGruppoInput} className={formGruppoStatus} style={{ "maxWidth":"none"}} type='text' value={selectedSetting!=null ? selectedSetting.settingGroup : ''} onChange={handleGruppoField}  />
                        </div>  
                    </div>

                </div>
                <div className='avp-cms-screen-content-col50' >

                </div>


            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}    />
    </div>     
  ); 

} 