import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import AvpGrafo from './AvpGrafo';
import AvpNodeSection from './AvpNodeSection';
import { userIsLogged, apiGetAvpAssistenteById, apiGetAllLingue, apiGetAvpGrafo, apiGetAvpNodoTipoAvvio, apiPostAvpArcoAdd, apiPostAvpNodoAdd, apiDeleteAvpNodo, apiPostAvpNodoAvatar, apiGetAvpNodoDuplica } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { findNode, findStartNode } from './AvpGrafoUtil';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

export default function GestioneAvpGrafoScreen() {  

  const navigate = useNavigate();

  const [avp, setAvp] = useState([])
  const [avpGrafo, setAvpGrafo] = useState(null)
  const [avpLingue, setAvpLingue] = useState([])
  const [selectedNode, setSelectedNode] = useState(null);
  const [tipoAvvioList, setTipoAvvioList] = useState([]);

  const nodeSectionRef = React.useRef();

  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);


  const breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'Crea/Modifica AVP', link:null },
    { title: 'Grafo AVP', link:null },
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {


  }, [avpGrafo, selectedNode])



  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    loadLingue();
  }



  function loadLingue() {
    console.log("loadLingue");

    apiGetAllLingue( function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAvpLingue(response.data);
      }
      loadNodoTipoAvvio();
      loadAvp();
    });

  }

  function loadNodoTipoAvvio() {
    apiGetAvpNodoTipoAvvio( function(response){
      if (response!=null && response.status>=200 && response.status<400) {
        setTipoAvvioList(response.data);
      }
    });
  }  

  function loadAvp() {
    const  url = window.location.pathname;
    const idAvp = url.substring(url.lastIndexOf('/') + 1);
    
    if (idAvp!=null && idAvp!="") {
      apiGetAvpAssistenteById(idAvp, avpCallback);
    }
    else {
      setAvp(null);
    }
  }

  function avpCallback(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      console.log("AvpAssistente", response.data);
      loadGrafo(response.data);
    }
  }

  function loadGrafo(pAvp) {
    
    if (pAvp!=null) {
      apiGetAvpGrafo(pAvp.idAvp, function(response){

        setAvp(pAvp);
        setAvpGrafo(response.data);

        console.log(" ---> avpGrafo", response.data);
        if (selectedNode!=null) {
          //refresh
          let nodo = findNode(selectedNode.idNodo, response.data.nodi);
          setSelectedNode(nodo);
          console.log("selected node: ", nodo);
        }
        else {
          let pIdNodo = getValueByQueryString("idnodo");
          if (pIdNodo!=null && pIdNodo!="") {
            let nodo = findNode(eval(pIdNodo), response.data.nodi);
            setSelectedNode(nodo);
            console.log("selected node: ", nodo);
          }
          else {
            let nodo =findStartNode(response.data.nodi);
            setSelectedNode(nodo);
            console.log("selected node: ", nodo);
          }

        }

      });
    }
  }  
 


  function footerCancelCallback() {
    console.log("cancel");
    navigate("/avp/"+avp.idAvp);
  }

  function footerSaveCallback() {
    console.log("save");
    grafoNodeSave();
  }  

  function grafoNodeSave() {
    const nodeData = nodeSectionRef.current.getData();
    console.log("FORM nodeData -> ", nodeData);
    if (nodeData!=null) {
        if (nodeData.props!=null) {
          selectedNode.tipoAvvio = nodeData.props.tipoAvvio;
          selectedNode.descNodo = nodeData.props.descNodo;
          selectedNode.apiEsterna = nodeData.props.apiEsterna;
          selectedNode.flagGoBack = nodeData.props.flagGoBack;
        }
        selectedNode.sysUser = "";
        selectedNode.sysDate = new Date().toISOString();
        selectedNode.sysAttivo = true;
        //save nodo
        apiPostAvpNodoAdd( selectedNode, function(response) {
          if (response!=null && response.status>=200 && response.status<400) {
            //check avatar
            if (nodeData.avatar!=null) {
              const avatar = {
                idNodo: selectedNode.idNodo,
                idLingua: nodeData.avatar.idLingua,
                idFile: nodeData.avatar.idFile,
                tipoAvatar: nodeData.avatar.tipoAvatar,
                formato: "",
                speechText: ""           
              };
              apiPostAvpNodoAvatar(avatar, function(avatarResponse) {
                if (avatarResponse!=null && avatarResponse.status>=200 && avatarResponse.status<400) {
                  setAlertSuccessOpen(true);
                  setAlertErrorOpen(false);
                  initPage();
                }
                else {
                  setAlertSuccessOpen(false);
                  setAlertErrorOpen(true);
                }
                
              });
            }
            else {
              setAlertSuccessOpen(true);
              setAlertErrorOpen(false);
              initPage();              
            }
          }
        });
      
    }
    
  }

  function grafoAddCallback() {
    console.log("grafoAdd", selectedNode);
    if (selectedNode!=null) {
      let tmpNode = {
        idAvp: avp.idAvp,
        descNodo: "nuovo",
        nodoStart: false,
        tipoAvvio: 1,
        apiEsterna: "",
        formato: "",
        flagGoBack: false,
        sysUser: "",
        sysDate: new Date().toISOString(),
        sysAttivo: true
      };
      apiPostAvpNodoAdd(tmpNode, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          let newNode = response.data;
          if (newNode!=null) {
            let tmpArco = {
              formato:"",
              idLingua: (avp.lingue!=null && avp.lingue.length>0 ? avp.lingue[0].idLingua : null),
              idNodoStart: selectedNode.idNodo,
              idNodoEnd: newNode.idNodo,
              ordine: 1,
              testo : "nuovo arco",
            };      
            apiPostAvpArcoAdd(tmpArco, function(response) {
              if (response!=null && response.status>=200 && response.status<400) {
                loadGrafo(avp);
              }
            });         
          }
        }
      });
    }
  }

  function grafoDeleteCallback() {
    console.log("grafoDelete", selectedNode);
    if (selectedNode!=null) {
      apiDeleteAvpNodo(selectedNode.idNodo, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          loadGrafo(avp);
        }
      });
    }
  }
  
  function grafoDuplicateCallback() {
    console.log("grafoDuplicate", selectedNode);
    if (selectedNode!=null) {
      apiGetAvpNodoDuplica(selectedNode.idNodo, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          loadGrafo(avp);
        }
      });
    }
  }

  function grafoSelectedCallback(node) {
    setSelectedNode(node);
  }  

  function handleNodeActionCallback(action, idNodo, idArco) {
    console.log("nodeAction: "+action+"  idNodo: "+idNodo+"   idArco: "+idArco);
    loadGrafo(avp);
  }

  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={breadCrumb} />
      <div id="avp-cms-screen-container"  >
        <div className="avp-cms-screen-content avp-cms-screen-content-grafocontent"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse>           
          <div className="avp-cms-screen-content-grafoleft"  >
            <div className="avp-cms-screen-content-row clearfix" style={{"color": "#000000", "fontSize": "24px", "fontWeight": "600"}}>
              {selectedNode!=null ? selectedNode.nodoStart ? 'START' : selectedNode.descNodo : ''}
              &nbsp;&nbsp;<span style={{"color": "#000000", "fontSize": "16px", "fontWeight": "300"}} >{selectedNode!=null ? '(ID: '+selectedNode.idNodo+")" : ''}</span>
            </div>                  
            <AvpNodeSection ref={nodeSectionRef} avp={avpGrafo} node={selectedNode} tipiAvvio={tipoAvvioList} avpAssistente={avp} lingue={avpLingue} nodeActionCallback={handleNodeActionCallback}  />
          </div>  
          <div className="avp-cms-screen-content-graforight"  >
            <AvpGrafo data={avpGrafo} pNode={selectedNode} addCallback={grafoAddCallback} deleteCallback={grafoDeleteCallback} duplicateCallback={grafoDuplicateCallback} selectedCallback={grafoSelectedCallback} ></AvpGrafo>
          </div>  
        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
    </div>     
  ); 

} 