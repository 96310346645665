import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import BreadCrumb from '../shared/header/BreadCrumb';
import { DataGrid } from '@mui/x-data-grid';
import { userIsLogged, apiGetAvpIstanzeByAvp, apiPostAvpIstanza, apiDeleteAvpIstanza } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GestioneAvpScreen() {  

  const navigate = useNavigate();

  const [currentAvp, setCurrentAvp] = useState(null)
  const [rows, setRows] = useState([])
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'AVP '+(currentAvp!=null ? currentAvp : ""), link:null },
    { title: 'Istanze', link:null },
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });



  function startPage() {
    console.log("startPage");

    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }

    
  }

  function initPage() {
    console.log("initPage");

    const url = window.location.pathname;
    const idAvp = getValueByQueryString("idavp");
    setCurrentAvp(idAvp);
    apiGetAvpIstanzeByAvp(idAvp, avpIstanzeCallback);
  }

  function stopPage() {
    console.log("stopPage");

  }


  function avpIstanzeCallback(response) {
    console.log(response);
    if (response!=null && response.status>=200 && response.status<400) {
      setRows(response.data); 
    }
    else {
      setRows([]); 
    }
       
  }

  function handleNew() {


    let istanza = {
      idUtente: 1, //
      idAvp: currentAvp,
      idTipoIstanza: 1,
      durataSessione: 0,
      qrCode: false,
      pin: "",
      sysUser: "",
      sysDate: new Date().toISOString(),
      sysAttivo: true
    };

    apiPostAvpIstanza(istanza, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        initPage();
      }
      else {

      }    
    });
  }



  const columns = [
    { field: 'idUtenteAvp', headerName: 'ID', flex: 1, headerClassName: 'tableHeader' },
    { field: 'userName', headerName: 'Utente', flex: 2, headerClassName: 'tableHeader' },
    { field: 'durataSessione', headerName: 'Dur. Sessione', flex: 1, headerClassName: 'tableHeader' },
    { field: 'qrCode', headerName: 'QR Code', flex: 1, headerClassName: 'tableHeader' },
    { field: 'pin', headerName: 'PIN', flex: 2, headerClassName: 'tableHeader' },
    { field: 'descTipoIstanza', headerName: 'Tipo istanza', flex: 2, headerClassName: 'tableHeader' },
    { field: 'action', headerName:'', flex: 2, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          console.log(params.row);
          const action = event.target.dataset.action;
          if (action=="edit") handleEdit(params.row.idUtenteAvp);
          else if (action=="delete") handleDelete(params.row.idUtenteAvp);
          else if (action=="qrcode") handleQRCode(params.row.idUtenteAvp);
          else if (action=="preview") handlePreview(params.row.idUtenteAvp, params.row.idTipoIstanza, params.row.idUtente, params.row.urlBase);
        };
  
        return <div className="tableActionButton">
                  <img  data-action="edit" src="/images/edit_btn.svg" onClick={onClick} />
                  <img data-action="delete" data-rowid="" src="/images/delete_btn.svg" onClick={onClick} />
                  <img data-action="qrcode" data-rowid="" src="/images/qrcode_btn.svg" onClick={onClick} />
                  <img data-action="preview" data-rowid="" src="/images/preview_btn.svg" onClick={onClick} />
                </div>;
      }
    }

  ];

  function handleEdit(idUtenteAvp) {
    const dest = "/avp/istanze/"+idUtenteAvp+"?idavp="+currentAvp;
    navigate(dest);    
  }
  

  function handleDelete(idUtenteAvp) {
    setIdToDelete(idUtenteAvp);
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);
    apiDeleteAvpIstanza( idToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        initPage();
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  };

  function handleQRCode(idUtenteAvp) {
    
  }  

  function handlePreview(idUtenteAvp, idTipoIstanza, idUtente, baseUrl) {
    let linkUrl = baseUrl+"/avp";
    if (idTipoIstanza==1) {
      let touchLinkUrl = linkUrl+"/touch"+"?idUtente="+idUtente+"&idAvp="+currentAvp;
      window.open(touchLinkUrl,'_blank');
    }
    else 
    if (idTipoIstanza==2 || idTipoIstanza==3) {
      //olo+smartphone
      let oloLinkUrl = linkUrl+"/olo"+"?idUtente="+idUtente+"&idAvp="+currentAvp;
      let interlinkUrl = linkUrl+"/interaction"+"?idUtente="+idUtente+"&idAvp="+currentAvp;
      window.open(oloLinkUrl,'_blank');
      window.open(interlinkUrl,'_blank');
    }
    
  }  

  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={breadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse>         
        <div className="avp-cms-screen-title">Gestisci le istanze dell’Assistente Virtuale Personalizzato</div>
        <div className="avp-cms-screen-content"  >
          
          <div className="avp-cms-screen-toolbar clearfix"  >
            <div className="avp-cms-screen-toolbar-title"  >{rows.length}&nbsp;istanze</div>
            <div className="avp-cms-screen-toolbar-filters"  ></div>
            <div className="avp-cms-screen-toolbar-add" onClick={handleNew} ><button>CREA ISTANZA</button></div>
          </div >
          
          <DataGrid
          disableColumnMenu
          getRowId={(row) => row.idUtenteAvp}
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            'tableRow'
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
        </div>
      </div>

      <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Conferma eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Eliminare l'istanza selezionata?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} >NO</Button>
          <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>
    </div>     
  ); 

} 