import React from 'react'
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import AvpNodeContentSection from './AvpNodeContentSection';
import AvpNodePropertiesSection from './AvpNodePropertiesSection';

const AvpNodeSection = forwardRef( function(props, ref) {  

  const nodePropertyRef = React.useRef();
  const nodeContentRef = React.useRef();

  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    setSelectedNode(props.node);

  }, [selectedNode])

  useImperativeHandle(ref, () => ({
      getData() {
        return getNodeData();
      }
  }));


  function getNodeData() {
    let propData = nodePropertyRef.current.getData();
    let contentData = nodeContentRef.current.getData();
    let sectionData = {
      props: propData,
      avatar: contentData
    }
    return sectionData;
  }
  
  if (props.node!=null ) {
    
    return (
      <div id="avp-gestioneavp-node-section"  >
          <div className="avp-cms-tab clearfix" >
            <div className={'avp-cms-tab-item avp-cms-tab-item-selected'} >
              Proprietà
            </div>            
          </div>
          <div className="avp-cms-tab-content clearfix" >
            <AvpNodePropertiesSection ref={nodePropertyRef} avp={props.avp} assistente={props.avpAssistente} node={props.node}  tipoAvvioList={props.tipiAvvio} lingue={props.lingue} actionCallback={props.nodeActionCallback}  />  
            <AvpNodeContentSection ref={nodeContentRef} avp={props.avp} assistente={props.avpAssistente} node={props.node}  tipoAvvioList={props.tipiAvvio} lingue={props.lingue} actionCallback={props.nodeActionCallback} />
          </div>  
      </div>     
    ); 
  }
  else {
    return (<div id="avp-gestioneavp-node-section"  ></div>)
  }



}); 
export default AvpNodeSection;
