import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetConFolder, apiPostConFolderAdd, apiDeleteConFolder } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import GestioneContenutiTree from './GestioneContenutiTree';

export default function GestioneContenutiFolderScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Contenuti', link:'/contenuti' },
    { title: 'Crea/Modifica Cartella', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [pageAction, setPageAction] = useState("new");
  const [parentFolderId, setParentFolderId] = useState(1);
 
  //FORM
  const [formDescStatus, setFormDescStatus] = useState('')
  const formDescInput = React.useRef();
  const formParentSelect = React.useRef();


  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });



  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    loadFolder();
  }

  function loadFolder() {
    const  url = window.location.pathname;
    const idFolder = url.substring(url.lastIndexOf('/') + 1);

    if (idFolder!==null && idFolder!=="" && idFolder!="-1") {
        //edit
        setPageAction("edit");
        apiGetConFolder(idFolder, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                console.log("setSelectedFolder", response.data);
                setParentFolderId(response.data.idFolder);
                setSelectedFolder(response.data);
                breadCrumb[0].link = "/contenuti?folder="+idFolder;
            }
        });
    }
    else {
        //new
        setPageAction("new");
        let queryParentFolder = getValueByQueryString("parent");
        setParentFolderId(queryParentFolder);
        let idGruppo = 0;
        let userAnag = getUserAnag();
        if (userAnag!=null) {
            idGruppo = userAnag.idGruppo;
        }
        let newFolder = {
          idGruppoUtente: idGruppo,
          descFolder: "nuova",
          idParentFolder: queryParentFolder!=null ? queryParentFolder : 1,
        };
        setSelectedFolder(newFolder); 
        console.log("setSelectedFolder", newFolder);
    }

    
  }


  function handleDescrizioneField(event) {
    let value = event.target.value;
    selectedFolder.descFolder = value;
    let newObj = Object.assign({}, selectedFolder);
    setSelectedFolder(newObj); 
  }

  function handleParentField(value) {
    selectedFolder.idParentFolder = eval(value);
    let newObj = Object.assign({}, selectedFolder);
    setSelectedFolder(newObj); 
  }



  function validateForm() {
    console.log("validateForm");
    let valid = true;

    if (pageAction=="new") {

    }


    return valid;    
} 

function saveFolder() {
    if (selectedFolder!=null) {
        apiPostConFolderAdd(selectedFolder, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
                navigate("/contenuti?folder="+selectedFolder.idParentFolder);
            } 
            else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
            } 
        });
    }
    else {
        console.log("Selected folder is null");
    }

}


function folderActionCallback(action, folderId) {
    console.log("folderActionCallback="+action+"  folder="+folderId);
    if (action!=null) {
      if (action=="select") {
        handleParentField(folderId);
        //setParentFolder(folderId);
      }
    }
  }


  function footerCancelCallback() {
    console.log("cancel");
    navigate("/contenuti?folder="+(selectedFolder!=null ? selectedFolder.idParentFolder : 1));
}

  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
        const desc = formDescInput.current.value;
        selectedFolder.descFolder = desc;
        setSelectedFolder(selectedFolder);

        saveFolder();
    }
  }  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione Cartella
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-desc" className='required' >Descrizione</label><br/>
                            <input id="avp-form-desc" ref={formDescInput} className={formDescStatus} style={{ "maxWidth":"none"}} type='text' value={selectedFolder!=null ? selectedFolder.descFolder : ''} onChange={handleDescrizioneField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-tipofile" className={"required "}>Cartella padre</label><br/>
                            <GestioneContenutiTree actionCallback={folderActionCallback} showToolbar={false} initialFolder={parentFolderId} showInitialFolder={pageAction=="new" ? true : false} autoExpandToSelection={true} showBin={false} />
                        </div>  
                    </div>  
                </div>
            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}    />
    </div>     
  ); 

} 