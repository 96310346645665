import React from 'react'
import { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';



const AvpNodePropertiesSection = forwardRef( function(props, ref) {  


  const [formDescrStatus, setFormDescrStatus] = useState('')
  const [currentNode, setCurrentNode] = useState(null)


  const descRef = React.useRef();
  const tipoAvvioRef = React.useRef();
  const apiEsternaRef = React.useRef();
  const flagGoBackRef = React.useRef();

  if (currentNode==null || (currentNode!=null && currentNode.idNodo!=props.node.idNodo)) {
    let tempNodo = Object.assign({}, props.node);
    setCurrentNode(tempNodo);
  }


  useEffect(() => {

    
  }, [props]);


  useImperativeHandle(ref, () => ({
    getData() {
      return getNodeData();
    }
  })); 
  
  function getNodeData() {
    if (validateForm()) {
      //if (currentNode.nodoStart==false) {
        const descNodo = descRef.current.value;
        const tipoAvvio = tipoAvvioRef.current.value;
        const apiEsterna = apiEsternaRef.current.value;   
        const flagGoBack = flagGoBackRef.current.checked;   
        let data = {
          descNodo: descNodo,
          tipoAvvio: tipoAvvio,
          apiEsterna: apiEsterna,
          flagGoBack: flagGoBack
        }
        return data;
      //}

      return null;
    }
    else
      return null;

  }


  function validateForm() {
    let valid = true;

    //if (currentNode.nodoStart==false) {
      const descNodo = descRef.current.value;
      const tipoAvvio = tipoAvvioRef.current.value;
      const apiEsterna = apiEsternaRef.current.value;
  
      if (descNodo==null || descNodo=="") {
        valid = false;
        setFormDescrStatus(" notvalid");
      }
      else {
        setFormDescrStatus("");
      }
    //}

    return valid;    
  }

  function onDescChange(event) {
    let value = event.target.value;
    currentNode.descNodo = value;
    let newNodo = Object.assign({}, currentNode);
    setCurrentNode(newNodo);
  }

  function onTipoAvvioChange(event) {
    let value = event.target.value;
    currentNode.tipoAvvio = value;
    let newNodo = Object.assign({}, currentNode);
    setCurrentNode(newNodo);
  }  

  function onApiEsternaChange(event) {
    let value = event.target.value;
    currentNode.apiEsterna = value;
    let newNodo = Object.assign({}, currentNode);
    setCurrentNode(newNodo);
  }  

  function onFlagGoBackChange(event) {
    let value = event.target.checked;
    currentNode.flagGoBack = value;
    let newNodo = Object.assign({}, currentNode);
    setCurrentNode(newNodo); 

  }    

  if (currentNode!=null  ) {

    return (
      <div id="avp-gestioneavp-node-properties-section"  >
  
          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-descr" className='required' >Descrizione</label><br/>
              <input ref={descRef} id="avp-form-descr" className={formDescrStatus} type='text' value={currentNode!=null ? currentNode.descNodo : ''}  onChange={onDescChange}  />
            </div>  
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-codeasset" className='required'>Tipo avvio</label><br/>
              <select value={currentNode!=null ? currentNode.tipoAvvio : ""} ref={tipoAvvioRef} onChange={onTipoAvvioChange} >
                { 
                  props.tipoAvvioList!=null ?
                    props.tipoAvvioList.map((tipo, i) => { 
                      return (<option key={tipo.idTipoAvvio} value={tipo.idTipoAvvio}  >{tipo.descTipoAvvio}</option>) 
                    })
                  : (<option></option>)
                }  
              </select>            
            </div>              
          </div>
          
          <div className="avp-cms-screen-content-row clearfix">
            <label htmlFor="avp-form-apiesterna" className='required'>Api esterna</label><br/>
            <textarea ref={apiEsternaRef} id="avp-form-apiesterna" type='text' style={{"maxWidth":"none"}}  rows="4" value={currentNode!=null && currentNode.apiEsterna!=null ? currentNode.apiEsterna : ''} onChange={onApiEsternaChange} ></textarea>
          </div>
  
          <div className="avp-cms-screen-content-row clearfix">
            <input id="avp-form-flagGoBack" ref={flagGoBackRef} type='checkbox' onChange={onFlagGoBackChange}  checked={currentNode!=null && currentNode.flagGoBack ? "checked" : ""} />            
            <label for="avp-form-flagGoBack">Flag Go Back</label>
          </div>



      </div>     
    );
 
  }
  else {
    return ( <div id="avp-gestioneavp-node-properties-section"  ></div>);
  }
  

}); 
export default AvpNodePropertiesSection;
