import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, apiGetAvpPlaylistContent, apiPostAvpPlaylistAddPlaylistContent } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { findNode } from './AvpGrafoUtil';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

export default function GestioneAvpContentPlaylistFileScreen() {  

  const navigate = useNavigate();

  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'Crea/Modifica AVP', link:null },
    { title: 'Grafo AVP', link:null },
    { title: 'Playlist File', link:null },
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedPlaylistFile, setSelectedPlaylistFile] = useState(null);
 
  //FORM
  const formDurataInput = React.useRef();



  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });



  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    const idAvp = getValueByQueryString("idavp");  
    const idNodo = getValueByQueryString("idnodo");
    breadCrumb[2].link = "/avp/grafo/"+idAvp+"?idnodo="+idNodo;  

    loadPlaylistFile();
  }



  function loadPlaylistFile() {
    const  url = window.location.pathname;
    const idPlaylistFile = getValueByQueryString("idplaylistfile");
    
    if (idPlaylistFile!=null && idPlaylistFile!="") {
      apiGetAvpPlaylistContent(idPlaylistFile, function(response){
        if (response!=null && response.status>=200 && response.status<400) {
          setSelectedPlaylistFile(response.data);
        }
      });

    }
  }


  function validateForm() {
    console.log("validateForm");
    let valid = true;


    return valid;    
  } 

  function handleDurataChange(event) {
    let value = event.target.value;
    selectedPlaylistFile.durataVisualizzazione = eval(value);
    
    let newObj = Object.assign({}, selectedPlaylistFile);
    setSelectedPlaylistFile(newObj);
    console.log(selectedPlaylistFile);
  }

  function handleUrlClick(event) {
    if (selectedPlaylistFile!=null) {
      window.open(selectedPlaylistFile.urlFile,'_blank');
    }
  }

function savePlaylistFile() {
  apiPostAvpPlaylistAddPlaylistContent(selectedPlaylistFile, function(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      setAlertSuccessOpen(true);
      setAlertErrorOpen(false);

      const idAvp = getValueByQueryString("idavp");  
      const idNodo = getValueByQueryString("idnodo");  
      navigate("/avp/grafo/"+idAvp+"?idnodo="+idNodo);
    }
    else {
      setAlertSuccessOpen(false);
      setAlertErrorOpen(true);
    }
  });

}


  function footerCancelCallback() {
    console.log("cancel");

    const idAvp = getValueByQueryString("idavp");  
    const idNodo = getValueByQueryString("idnodo");  
    navigate("/avp/grafo/"+idAvp+"?idnodo="+idNodo);
  }

  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
      savePlaylistFile();
    }
  }  




  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content "  >
  
          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-gestioneavp-nodearcs-section-title" >
              Gestione Playlist File
            </div>
          </div>


            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-idplaylistfile" className='required' >ID Playlist</label><br/>
                <input id="avp-form-idplaylistfile" type='text' value={selectedPlaylistFile!=null ? selectedPlaylistFile.idPlaylist : ''} disabled={true} />
              </div>  
            </div>

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-file" className='required' >File</label><br/>
                <input id="avp-form-file" type='text' value={selectedPlaylistFile!=null ? selectedPlaylistFile.nomeFile : ''} disabled={true} />
              </div>  
            </div>

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column" style={{"width":"50%"}}>
                <label htmlFor="avp-form-descrizione" className='required' >Descrizione</label><br/>
                <input id="avp-form-descrizione" type='text' style={{ "maxWidth":"none"}}  value={selectedPlaylistFile!=null ? selectedPlaylistFile.descFile : ''} disabled={true} />
              </div>  
            </div>            

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column" style={{"width":"50%"}}>
                <label htmlFor="avp-form-url" className='required' >Url</label><br/>
                <input id="avp-form-url" type='text' style={{ "maxWidth":"none"}}  value={selectedPlaylistFile!=null ? selectedPlaylistFile.urlFile : ''} disabled={true} onClick={handleUrlClick} />
              </div>  
            </div>      

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column" style={{"width":"50%"}}>
                <label htmlFor="avp-form-durata" className='required' >Durata visualizzazione</label><br/>
                <input ref={formDurataInput} id="avp-form-durata" style={{ "maxWidth":"none"}} type='number' min="0" value={selectedPlaylistFile!=null ? selectedPlaylistFile.durataVisualizzazione : '0'}  onChange={handleDurataChange} />
              </div>  
            </div>


        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
    </div>     
  ); 

} 