import React from 'react'
import { useState } from 'react';

export default function BreadCrumbItem({itemTitle, itemLink, isLast}) {  


    return (
      <div className={isLast ? 'avp-breadcrumb-item-last clearfix' : 'avp-breadcrumb-item clearfix'}  >
       
        {
            (itemLink!=null && itemLink.length>0) ? 
                (<a href={itemLink}><span >{itemTitle}</span></a>)
                :
                (<span >{itemTitle}</span>)
        }
        {isLast==false ? (' > ') : ('')}
        
      </div>

    ); 
  
  } 