import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetAnaUtenteRuoli, apiGetAnaUtente, apiPostAnaUtente, apiGetAnaGruppoUtenteIndex } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


export default function GestioneUtenteDetailScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Utenti', link:'/utenti' },
    { title: 'Crea/Modifica Utente', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageAction, setPageAction] = useState("new");
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
 
  //FORM
  const [formNomeStatus, setFormNomeStatus] = useState('');
  const [formCognomeStatus, setFormCognomeStatus] = useState('');
  const [formEmailStatus, setFormEmailStatus] = useState('');
  const [formTelefonoStatus, setFormTelefonoStatus] = useState('');
  const [formUsernameStatus, setFormUsernameStatus] = useState('');
  const [formPasswordStatus, setFormPasswordStatus] = useState('');

  const formNomeInput = React.useRef();
  const formCognomeInput = React.useRef();
  const formEmailInput = React.useRef();
  const formTelefonoInput = React.useRef();
  const formUsernameInput = React.useRef();
  const formPasswordInput = React.useRef();
  const formRuoloSelect = React.useRef();
  const formGruppoSelect = React.useRef();


  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {

  
  }, [roles, groups])


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    apiGetAnaUtenteRuoli(function(response) {
        let rol = null;
        let gru = null;
        if (response!=null && response.status>=200 && response.status<400) {
            rol = response.data;
        }
        apiGetAnaGruppoUtenteIndex(0, function(respGruppi) {
            if (respGruppi!=null && respGruppi.status>=200 && respGruppi.status<400) {
                gru = respGruppi.data;
            }

            setRoles(rol);
            setGroups(gru);
            loadUser();
        });

    });
    
  }

  function loadUser() {
    const  url = window.location.pathname;
    const idUtente = url.substring(url.lastIndexOf('/') + 1);

    if (idUtente!==null && idUtente!=="" && idUtente!="-1") {
        //edit
        setPageAction("edit");
        
        apiGetAnaUtente(idUtente, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setSelectedUser(response.data);
            }
        });
    }
    else {
        //new
        setPageAction("new");
        let newUser = {
            idRuolo: 0,
            idGruppo: 0,
            nome: "",
            cognome: "",
            emailUtente: "",
            telefono: "",
            userName: "",
            passwordHash: "",
            sysUser: "",
            sysDate: new Date().toISOString(),
            sysAttivo: true
        };
        setSelectedUser(newUser); 
    }
  }


  function handleNomeField(event) {
    let value = event.target.value;
    selectedUser.nome = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }

  function handleCognomeField(event) {
    let value = event.target.value;
    selectedUser.cognome = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }

  function handleEmailField(event) {
    let value = event.target.value;
    selectedUser.emailUtente = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }

  function handleTelefonoField(event) {
    let value = event.target.value;
    selectedUser.telefono = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }

  function handleUsernameField(event) {
    let value = event.target.value;
    selectedUser.userName = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }  

  function handlePasswordField(event) {
    let value = event.target.value;
    selectedUser.passwordHash = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }  

  function handleRuoloField(event) {
    let value = event.target.value;
    selectedUser.idRuolo = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }

  function handleGruppoField(event) {
    let value = event.target.value;
    selectedUser.idGruppo = value;
    let newObj = Object.assign({}, selectedUser);
    setSelectedUser(newObj); 
  }


  function validateForm() {
    console.log("validateForm");
    let valid = true;

    const nome = formNomeInput.current.value;
    const cognome = formCognomeInput.current.value;
    const email = formEmailInput.current.value;
    const telefono = formTelefonoInput.current.value;
    const username = formUsernameInput.current.value;
    const password = formPasswordInput.current.value;
    
    if (nome==null || nome=="") {
        valid = false;
        setFormNomeStatus(" notvalid");
    }
    else {
        setFormNomeStatus("");
    }

    if (cognome==null || cognome=="") {
        valid = false;
        setFormCognomeStatus(" notvalid");
    }
    else {
        setFormCognomeStatus("");
    }

    if (email==null || email=="") {
        valid = false;
        setFormEmailStatus(" notvalid");
    }
    else {
        setFormEmailStatus("");
    }   
 /*   
    if (telefono==null || telefono=="") {
        valid = false;
        setFormTelefonoStatus(" notvalid");
    }
    else {
        setFormTelefonoStatus("");
    }     
*/    
    if (username==null || username=="") {
        valid = false;
        setFormUsernameStatus(" notvalid");
    }
    else {
        setFormUsernameStatus("");
    }  
    
    if (password==null || password=="") {
        valid = false;
        setFormPasswordStatus(" notvalid");
    }
    else {
        setFormPasswordStatus("");
    }      

    return valid;    
} 

function saveUser() {
    if (selectedUser!=null) {
        
        apiPostAnaUtente(selectedUser, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
                navigate("/utenti");
            } 
            else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
            } 
        });
    }
    else {
        console.log("Selected user is null");
    }

}


function footerCancelCallback() {
    console.log("cancel");
    navigate("/utenti");
}

function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
        saveUser();
    }
}  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione Utente
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-nome" className='required' >Nome</label><br/>
                            <input id="avp-form-nome" ref={formNomeInput} className={formNomeStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.nome : ''} onChange={handleNomeField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-cognome" className='required' >Cognome</label><br/>
                            <input id="avp-form-cognome" ref={formCognomeInput} className={formCognomeStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.cognome : ''} onChange={handleCognomeField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-email" className='required' >Email</label><br/>
                            <input id="avp-form-email" ref={formEmailInput} className={formEmailStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.emailUtente : ''} onChange={handleEmailField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-telefono"  >Telefono</label><br/>
                            <input id="avp-form-telefono" ref={formTelefonoInput} className={formTelefonoStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.telefono : ''} onChange={handleTelefonoField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-username" className='required' >Username</label><br/>
                            <input id="avp-form-username" ref={formUsernameInput} className={formUsernameStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.userName : ''} onChange={handleUsernameField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-password" className='required' >Password</label><br/>
                            <input id="avp-form-password" ref={formPasswordInput} className={formPasswordStatus} style={{ "maxWidth":"none"}} type='text' value={selectedUser!=null ? selectedUser.passwordHash : ''} onChange={handlePasswordField}  />
                        </div>  
                    </div>

                </div>
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-role" className={"required "}>Ruolo</label><br/>
                            <select ref={formRuoloSelect} id="avp-form-role" style={{ "maxWidth":"none"}} value={selectedUser!=null ? selectedUser.idRuolo : ""} onChange={handleRuoloField}>
                                { 
                                    roles!=null && roles.length>0 ?
                                    roles.map((item, i) => { 
                                        return (<option key={item.idRuolo} value={item.idRuolo}  >{item.descrizioneRuolo}</option>) 
                                    })
                                    : (<option></option>)
                                    
                                    
                                }  
                            </select> 
                        </div>  
                    </div> 

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-group" className={"required "}>Gruppo</label><br/>
                            <select ref={formGruppoSelect} id="avp-form-group" style={{ "maxWidth":"none"}} value={selectedUser!=null ? selectedUser.idGruppo : ""} onChange={handleGruppoField}>
                                { 
                                    groups!=null && groups.length>0 ?
                                    groups.map((item, i) => { 
                                        return (<option key={item.idGruppoUtente} value={item.idGruppoUtente}  >{item.descrizioneGruppo}</option>) 
                                    })
                                    : (<option></option>)
                                    
                                    
                                }  
                            </select> 
                        </div>  
                    </div>                     

                </div>


            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}    />
    </div>     
  ); 

} 