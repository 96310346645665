import React from 'react'
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { apiPostAvpNodoAvatar, apiGetAvpNodoAvatar, apiGetAvpNodoAllAvatars, apiGetAvpNodoTipoAvatar, apiGetConFile } from "../shared/manager/api_manager";
import AvpNodeContentPlaylistSection from './AvpNodeContentPlaylistSection';
import AvpNodeContentArcsSection from './AvpNodeContentArcsSection';


const AvpNodeContentSection = forwardRef( function(props, ref) {  

  const navigate = useNavigate();

  const [tipoAvatarList, setTipoAvatarList] = useState([])
  const [avatarList, setAvatarList] = useState([])
  const [currentLanguage, setCurrentLanguage] = useState("")
  const [currentNode, setCurrentNode] = useState(null)
  const [currentNodeAvatar, setCurrentNodeAvatar] = useState(null)
  const [currentNodeAvatarFile, setCurrentNodeAvatarFile] = useState(null)
  const [currentNodeArchi, setCurrentNodeArchi] = useState([])

  //FORM
  const [formAvatarStatus, setFormAvatarStatus] = useState('')
  const formTipoAvatarSelect = React.useRef();
  const formAvatarSelect = React.useRef();

  if (currentNode==null || (currentNode!=null && currentNode.idNodo!=props.node.idNodo)) {
    let tempNodo = Object.assign({}, props.node);
    setCurrentNode(tempNodo);
  }



  useEffect(() => {
    console.log("props node:", props.node);
    setCurrentNodeArchi(props.node.archi);
    initPage();
  }, [props]);

  useImperativeHandle(ref, () => ({
    getData() {
      return getNodeData();
    }
  })); 
  
  function getNodeData() {
    if (validateForm()) {
      
      const tipoAvatar = formTipoAvatarSelect.current.value;
      const avatar = formAvatarSelect.current.value;
      let data = null;
      if (tipoAvatar!=null && tipoAvatar!="") {
        data = {
          tipoAvatar: tipoAvatar,
          idFile: avatar,
          idLingua: currentLanguage.idLingua
        }
      }

      return data;   
    }
    else
      return null;

  }

  function validateForm() {
    let valid = true;

    const tipoAvatar = formTipoAvatarSelect.current.value;
    const avatar = formAvatarSelect.current.value;

    if (tipoAvatar!=null && tipoAvatar!="") {
      if (avatar==null || avatar=="") {
        valid = false;
        setFormAvatarStatus(" notvalid");
      }
      else {
        setFormAvatarStatus("");
      }
    }
    else {
      setFormAvatarStatus("");
    }

    return valid;   
  }

  function initPage() {
    console.log("node", props.node);
    console.log("lingue", props.lingue);
    let currentLingua = props.assistente!=null && props.assistente.lingue!=null ? props.assistente.lingue[0] : "";
    console.log("currentLingua", currentLingua);
    setCurrentLanguage(currentLingua);
    apiGetAvpNodoTipoAvatar(function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        //load current avatar
        apiGetAvpNodoAvatar( props.node.idNodo, currentLingua.idLingua,  function(avatarResponse) {
          if (avatarResponse!=null && avatarResponse.status>=200 && avatarResponse.status<400) {
            console.log("currentAvatar", avatarResponse.data);
            if (avatarResponse.data!=null && avatarResponse.data.idFile!=null && avatarResponse.data.idFile!=0) {
              apiGetConFile(avatarResponse.data.idFile, function(fileResponse) {
                if (fileResponse!=null && fileResponse.status>=200 && fileResponse.status<400) {
                  setCurrentNodeAvatarFile(fileResponse.data);
                }
                setCurrentNodeAvatar(avatarResponse.data);
                setTipoAvatarList(response.data);
              });
            }
            else {
              setCurrentNodeAvatar(avatarResponse.data);
              setTipoAvatarList(response.data);
              setCurrentNodeAvatarFile(null);
            }
          }
          else {
            console.log("currentAvatar", null);
            setCurrentNodeAvatar(null);
            setCurrentNodeAvatarFile(null);
            setTipoAvatarList(response.data);
          }
          
        });
      }
      else {
        setTipoAvatarList(null);
      }
    });
  }

  function getAllAvatars(tipoAvatar, callback) {
    if (tipoAvatar!=null && tipoAvatar!=="undefined") {
      apiGetAvpNodoAllAvatars(tipoAvatar, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          console.log("avatars by tipo:",response.data);
          callback(response.data);
        }
        else {
          callback(null);
        }
      });
    }
    else {
      callback(null);
    }
  }

  function onTipoAvatarChange(event) {
    let tipoAvatar = eval(event.target.value);
    let tempNodeAvatar = null;
    if (currentNodeAvatar==null) {
      tempNodeAvatar = {};
    }
    else {
      tempNodeAvatar = currentNodeAvatar;
    }
    tempNodeAvatar.tipoAvatar = tipoAvatar;
    let newObj = Object.assign({}, tempNodeAvatar);
    setCurrentNodeAvatar(newObj);
    

  }

  function onAvatarChange(event) {
    let avatar = eval(event.target.value);

    if (currentNodeAvatar!=null) {
      currentNodeAvatar.idFile = avatar;
      let newObj = Object.assign({}, currentNodeAvatar);
      setCurrentNodeAvatar(newObj);
    }   
  }

  function handleSelectFile() {
    //open file picker
    let tipoFile = 4;
    if (currentNodeAvatar!=null && currentNodeAvatar.tipoAvatar!=null && currentNodeAvatar.tipoAvatar!=="") {
      if (currentNodeAvatar.tipoAvatar==1) {
        tipoFile = 5;
      }
      else
      if (currentNodeAvatar.tipoAvatar==2) {
        tipoFile = 4;
      }
      navigate("/contenuti/pick?action=addtonode&tipocontenuto="+tipoFile+"&node="+currentNode.idNodo+"&avp="+props.avp.idAvp+"&lingua="+currentLanguage.idLingua);
    }

  }


  return (
    <div id="avp-gestioneavp-node-content-section"  >

        <div className="avp-cms-tab clearfix" >
            <div className={'avp-cms-tab-item avp-cms-tab-item-selected'} >
              Italiano
            </div>   
            <div className={'avp-cms-tab-item'} >
              Inglese
            </div>              
        </div>

        <div className="avp-cms-screen-content-row clearfix">
            <AvpNodeContentArcsSection pAvp={props.avp} pNode={currentNode} pArchi={currentNodeArchi} plingue={props.lingue}  pActionCallback={props.actionCallback} />    
        </div>

        
        <div className="avp-cms-screen-content-row clearfix">
          <div className="avp-cms-screen-content-column">
            <label htmlFor="avp-form-tipoavatar" className='required'>Tipo avatar</label><br/>
            <select ref={formTipoAvatarSelect} id="avp-form-tipoavatar" style={{ "minWidth": "200px"}}  value={currentNodeAvatar!=null ? currentNodeAvatar.tipoAvatar : ""} onChange={onTipoAvatarChange} >
                  <option key={-1} value=""  >Selezionare...</option>
                  { 
                    tipoAvatarList!=null && tipoAvatarList.length>0 ?
                    tipoAvatarList.map((item, i) => { 
                      return (<option key={item.idTipoAvatar} value={item.idTipoAvatar}  >{item.descTipoAvatar}</option>) 
                        
                      })
                    : (<option></option>)
                      
                    
                  }  
             </select> 
          </div>    
          <div className="avp-cms-screen-content-column" style={{ "marginRight":"0"}} >
            <label htmlFor="avp-form-avatar" className='required'>Avatar</label><br/>
            <input id="avp-form-filetoanimate"  style={{ "maxWidth":"none",  "width":"68%", "position":"relative", "float":"left"}} type='text' disabled={true} value={currentNodeAvatarFile!=null ? currentNodeAvatarFile.descFile : ''}  />
            <button onClick={handleSelectFile} style={{ "height": "34px", "marginLeft": "0px", "width":"28%", "position":"relative", "float":"right"}} >SELEZIONA</button>
          </div>                      
        </div>

        <div className="avp-cms-screen-content-row clearfix">
            <AvpNodeContentPlaylistSection pAvp={props.avp} pNode={props.node} pLingua={currentLanguage}  pActionCallback={props.actionCallback} />    
        </div>


    </div>     
  ); 

}); 
export default AvpNodeContentSection;