import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetAvatarGenere, apiGetAvatarLanguages, apiGetConFile, apiGetAvatarVoices, apiGetAvatarVoice, apiPostCreateAvatar, apiPostConFileUpdate, apiPostConFileUpload } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import PlaylistItem from './PlaylistItem';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function GestioneContenutiAvatarScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Contenuti', link:'/contenuti' },
    { title: 'Crea Avatar', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedAvatarFile, setSelectedAvatarFile] = useState(null);
  const [selectedAvatarGenere, setSelectedAvatarGenere] = useState(null);
  const [selectedAvatarLang, setSelectedAvatarLang] = useState(null);
  const [selectedAvatarVoice, setSelectedAvatarVoice] = useState(null);
  const [avatarGenere, setAvatarGenere] = useState([]);
  const [avatarLanguages, setAvatarLanguages] = useState([]);
  const [avatarVoices, setAvatarVoices] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [inputFile, setInputFile] = useState(null);
  const [pageAction, setPageAction] = useState("new");
  const [selectedFolderId, setSelectedFolderId] = useState(1);
 
  //FORM
  const [formFileToAnimateStatus, setFormFileToAnimateStatus] = useState('')
  const [formDescStatus, setFormDescStatus] = useState('')
  const [formGenereStatus, setFormGenereStatus] = useState('')
  const [formLangStatus, setFormLangStatus] = useState('')
  const [formVoiceStatus, setFormVoiceStatus] = useState('')
  const [formTextStatus, setFormTextStatus] = useState('')

  
  const formFileToAnimateInput = React.useRef();
  const formDescInput = React.useRef();
  const formTextInput = React.useRef();
  const formSubtitlesCheckbox = React.useRef();


  const pSize  = {
    width: 640,
    height: 360
  }; 

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {
    loadAvatarVoices();
  
  }, [selectedAvatarLang, selectedAvatarGenere, selectedAvatarVoice, selectedAvatarFile, selectedAvatar, showLoader])

  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    apiGetAvatarGenere(function(response) {
        let genereList = [];
        let langList = [];
        if (response!=null && response.status>=200 && response.status<400) {
            genereList = response.data; 
        }
        apiGetAvatarLanguages( function(langResp) {
            if (langResp!=null && langResp.status>=200 && langResp.status<400) {
                langList = langResp.data; 
            }

            setAvatarGenere(genereList);
            setAvatarLanguages(langList);

            let idFile = getValueByQueryString("idfile");
            if (idFile!=null && idFile!="") {
                loadAvatarFile(idFile);
            }
            else {
                loadAvatar(null);
            }
        });
    });

  }

  function handleDescrizioneField(event) {
    let value = event.target.value;
    selectedAvatar.descAvatar = value;
    let newObj = Object.assign({}, selectedAvatar);
    setSelectedAvatar(newObj); 
  }

  function handleGenereField(event) {
    let value = event.target.value;
    let genere = null;
    if (avatarGenere!=null && avatarGenere.length>0) {
        for (let i=0; i<avatarGenere.length; i++) {
            if (avatarGenere[i].idGenere==value) {
                genere = avatarGenere[i];
                break;
            }
        }
    }
    setSelectedAvatarGenere(genere);
  }

  function handleLangField(event) {
    let value = event.target.value;
    let lang = null;
    if (avatarLanguages!=null && avatarLanguages.length>0) {
        for (let i=0; i<avatarLanguages.length; i++) {
            if (avatarLanguages[i].idLingua==value) {
                lang = avatarLanguages[i];
                break;
            }
        }
    }
    setSelectedAvatarLang(lang);
  }

  function handleVoiceField(event) {
    let value = event.target.value;
    let voice = null;
    if (avatarVoices!=null && avatarVoices.length>0) {
        for (let i=0; i<avatarVoices.length; i++) {
            if (avatarVoices[i].id==value) {
                voice = avatarVoices[i];
                break;
            }
        }
    }
    
    setSelectedAvatarVoice(voice);
    if (voice!=null && selectedAvatar!=null) {
        selectedAvatar.idVoce = voice.id;
        selectedAvatar.provider = voice.provider;
        let newObj = Object.assign({}, selectedAvatar);
        setSelectedAvatar(newObj); 
    }
    
  }

  function handleTextField(event) {
    let value = event.target.value;
    selectedAvatar.text = value;
    let newObj = Object.assign({}, selectedAvatar);
    setSelectedAvatar(newObj); 
  }

  function handleSubtitlesChange(event) {
    let value = event.target.checked;
    selectedAvatar.subtitles = value ? "true" : "false";
    let newObj = Object.assign({}, selectedAvatar);
    setSelectedAvatar(newObj); 

  }    

  function loadAvatarVoices() {
    if (selectedAvatarLang!=null && selectedAvatarGenere!=null) {
        apiGetAvatarVoices(selectedAvatarLang.idLingua, selectedAvatarGenere.idGenere, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAvatarVoices(response.data); 
            }
        });
    }
    else {
        //console.warn("selectedAvatarLang", selectedAvatarLang);
        //console.warn("selectedAvatarGenere", selectedAvatarGenere);
    }
    
  }


  function loadAvatar(idFile) {
    const  url = window.location.pathname;
    const idAvatar = getValueByQueryString("idavatar");
    
    
    if (idAvatar!==null && idAvatar!=="") {
        //edit
        setPageAction("edit");

    }
    else {
        //new
        setPageAction("new");
        const idFolder = getValueByQueryString("folder");
        let idUtente = 0;
        let userAnag = getUserAnag();
        if (userAnag!=null) {
            idUtente = userAnag.idutente;
        }
        let newAvatar = {
            idFile: (selectedAvatarFile!=null ? selectedAvatarFile.idFile : idFile), 
            descAvatar: "nuovo",
            provider: "",
            idVoce: "",
            text: "",
            subtitles: "false",
            talkId: "",
            idUtente: idUtente,
            idFolder: eval(idFolder)
        };
        setSelectedAvatar(newAvatar);
        setSelectedFolderId(idFolder); 
        console.log("setSelectedAvatar", newAvatar);
    }
  }

  function loadAvatarFile(idFile) {
    apiGetConFile(idFile, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
            setSelectedAvatarFile(response.data); 
            loadAvatar(response.data.idFile);
        }
    });
  }

  function handleSelectFile() {
    //open file picker
    navigate("/contenuti/pick?action=addtoavatar&tipocontenuto=2&folder="+selectedFolderId);
  }


  function validateForm() {
    console.log("validateForm");
    let valid = true;

    const desc = formDescInput.current.value;
    const text = formTextInput.current.value;
    
    
    if (desc==null || desc=="") {
        valid = false;
        setFormDescStatus(" notvalid");
    }
    else {
        setFormDescStatus("");
    }

    if (text==null || text=="") {
        valid = false;
        setFormTextStatus(" notvalid");
    }
    else {
        setFormTextStatus("");
    }




    return valid;    
} 

function saveAvatar() {
    if (selectedAvatar!=null) {

        if (pageAction=="new") {
            setShowLoader(true);
            apiPostCreateAvatar(selectedAvatar, function(response) {
                setShowLoader(false);
                if (response!=null && response.status>=200 && response.status<400) {
                    if (selectedFolderId!=null) {
                        navigate("/contenuti?folder"+selectedFolderId);
                    }
                    else {
                        navigate("/contenuti");
                    }
                } 
                else {
                    setAlertSuccessOpen(false);
                    setAlertErrorOpen(true);
                    
                }                               
            });
        }
    }
    else {
        console.log("Selected avatar is null");
    }

}

function footerCancelCallback() {
    console.log("cancel");
    if (selectedFolderId!=null) {
        navigate("/contenuti?folder"+selectedFolderId);
    }
    else {
        navigate("/contenuti");
    }
    
  }


  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
        const desc = formDescInput.current.value;
        const text = formTextInput.current.value;
        selectedAvatar.descAvatar = desc;
        selectedAvatar.text = text;
        setSelectedAvatar(selectedAvatar);

        saveAvatar();
    
    }
  }  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione Avatar
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-filetoanimate" className='required' >Seleziona file da animare</label><br/>
                            <input id="avp-form-filetoanimate" ref={formFileToAnimateInput} className={formFileToAnimateStatus} style={{ "maxWidth":"none"}} type='text' disabled={true} value={selectedAvatarFile!=null ? selectedAvatarFile.descFile : ''}  />
                            <button onClick={handleSelectFile} style={{"borderRadius": "10px", "height": "34px", "marginTop": "20px", "width":"auto"}} >SELEZIONA</button>
                        </div>  
                    </div>
                    <div style={{"display": (selectedAvatarFile!=null ? "block" : "none") }} >
                        <div className="avp-cms-screen-content-row clearfix">
                            <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                <label htmlFor="avp-form-desc" className='required' >Descrizione</label><br/>
                                <input id="avp-form-desc" ref={formDescInput} className={formDescStatus} style={{ "maxWidth":"none"}} type='text' value={selectedAvatar!=null ? selectedAvatar.descAvatar : ''} onChange={handleDescrizioneField}  />
                            </div>  
                        </div>
                        <div className="avp-cms-screen-content-row clearfix">
                            <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                <label htmlFor="avp-form-genere" className={"required "}>Genere</label><br/>
                                <select id="avp-form-genere" style={{ "maxWidth":"none"}} className={formGenereStatus}  value={selectedAvatarGenere!=null ? selectedAvatarGenere.idGenere : ""} onChange={handleGenereField}>
                                    <option key={-1} value=""  >Selezionare...</option>
                                    { 
                                        avatarGenere!=null && avatarGenere.length>0 ?
                                        avatarGenere.map((item, i) => { 
                                            return (<option key={item.idGenere} value={item.idGenere}  >{item.descGenere}</option>) 
                                        })
                                        : (<option></option>)
                                        
                                        
                                    }  
                                </select> 
                            </div>  
                        </div>  
                        <div className="avp-cms-screen-content-row clearfix">
                            <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                <label htmlFor="avp-form-lang" className={"required "}>Lingua</label><br/>
                                <select id="avp-form-lang" style={{ "maxWidth":"none"}} className={formLangStatus}  value={selectedAvatarLang!=null ? selectedAvatarLang.idLingua : ""} onChange={handleLangField}>
                                    <option key={-1} value=""  >Selezionare...</option>
                                    { 
                                    
                                        avatarLanguages!=null && avatarLanguages.length>0 ?
                                        avatarLanguages.map((item, i) => { 
                                            return (<option key={item.idLingua} value={item.idLingua}  >{item.descrizione}</option>) 
                                        })
                                        : (<option></option>)
                                        
                                        
                                    }  
                                </select> 
                            </div>  
                        </div> 
                        <div className="avp-cms-screen-content-row clearfix">
                            <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                <label htmlFor="avp-form-voce" className={"required "}>Voce</label><br/>
                                <select id="avp-form-voce" style={{ "maxWidth":"none"}} className={formVoiceStatus}  value={selectedAvatarVoice!=null ? selectedAvatarVoice.id : ""} onChange={handleVoiceField}>
                                    <option key={-1} value=""  >Selezionare...</option>
                                    { 
                                        avatarVoices!=null && avatarVoices.length>0 ?
                                        avatarVoices.map((item, i) => { 
                                            return (<option key={item.id} value={item.id}  >{item.name}</option>) 
                                        })
                                        : (<option></option>)
                                        
                                        
                                    }  
                                </select> 
                            </div>  
                        </div>
                        <div className="avp-cms-screen-content-row clearfix">
                            <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                                <label htmlFor="avp-form-text" className='required' >Testo</label><br/>
                                <textarea ref={formTextInput} id="avp-form-text" className={formTextStatus} style={{ "maxWidth":"none"}} onChange={handleTextField} >{selectedAvatar!=null ? selectedAvatar.text : ''}</textarea>
                            </div>  
                        </div>
                        <div className="avp-cms-screen-content-row clearfix">
                            <input id="avp-form-subtitles" ref={formSubtitlesCheckbox} type='checkbox' onChange={handleSubtitlesChange}  checked={selectedAvatar!=null && selectedAvatar.subtitles=="true" ? "checked" : ""} />            
                            <label for="avp-form-subtitles">Sottotitoli</label>
                        </div>
                    </div>

                </div>
                <div className='avp-cms-screen-content-col50' >
                    <PlaylistItem item={selectedAvatarFile} loop={false} size={pSize} />
                </div>                
            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
      <Box sx={{ display: showLoader ? 'flex' : 'none', position: 'fixed', top: '50%', left:'50%', marginLeft: '-60px' }}>
        <CircularProgress 
            sx={(theme) => ({
                color: '#d21404'
            })}
            size={120}
            thickness={4}        
        />
      </Box>
    </div>     
  ); 

} 