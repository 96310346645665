import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';


export default function DashboardScreen() {  

  const navigate = useNavigate();

  useEffect(()=>{
    if(!localStorage.getItem("token")){
      navigate("/login");
    }else{
      //getUser();
    }
    },[]
  );

  return (
    <div id="avp-dashboard-screen"  >
      <AvpHeader selectedMenuId={"dashboard"} />
      <div id="avp-cms-screen-content"  >
      DASHBOARD
      </div>
      
    </div>
  ); 

} 