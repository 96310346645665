import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'  
import { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './App.css';

import { useEffectOnce } from './shared/manager/use_effect_once';
import Notfound from './shared/notfound' 
import LoginScreen from './login/LoginScreen'
import DashboardScreen from './dashboard/DashboardScreen'
import GestioneAvpScreen from './avp/GestioneAvpScreen'
import GestioneAvpDetailScreen from './avp/GestioneAvpDetailScreen'
import GestioneAvpGrafoScreen from './avp/GestioneAvpGrafoScreen'
import GestioneAvpIstanzeScreen from './avp/GestioneAvpIstanzeScreen'
import GestioneAvpIstanzeDetailScreen from './avp/GestioneAvpIstanzeDetailScreen'
import GestioneUtentiScreen from './utenti/GestioneUtentiScreen'
import GestioneContenutiScreen from './contenuti/GestioneContenutiScreen'
import GestioneContenutiFileScreen from './contenuti/GestioneContenutiFileScreen'
import GestioneContenutiPickScreen from './contenuti/GestioneContenutiPickScreen'
import GestioneSistemaScreen from './sistema/GestioneSistemaScreen'
import GestioneAvpArcoScreen from './avp/GestioneAvpArcoScreen';
import GestioneAvpContentPlaylistFileScreen from './avp/GestioneAvpContentPlaylistFileScreen';
import GestioneContenutiFolderScreen from './contenuti/GestioneContenutiFolderScreen';
import GestioneContenutiAvatarScreen from './contenuti/GestioneContenutiAvatarScreen';
import GestioneUtenteDetailScreen from './utenti/GestioneUtenteDetailScreen';
import GestioneGruppiUtenteScreen from './utenti/GestioneGruppiUtenteScreen';
import GestioneGruppoUtenteDetailScreen from './utenti/GestioneGruppoUtenteDetailScreen';
import GestioneSistemaDetailScreen from './sistema/GestioneSistemaDetailScreen';

function App() {
  

  useEffectOnce(() => {
    startAPP();
    
    return () => stopAPP();
  });

  //START APP
  function startAPP() {
    console.log('startAPP ');


  }

  //STOP APP
  function stopAPP() {
    console.log('stopAPP');

  }


  return (
    <Router>  
      <Routes>  
        <Route path="/login" element={<LoginScreen  />} />  
        <Route path="/" element={<DashboardScreen  />} />  
        <Route path="/avp" element={<GestioneAvpScreen  />} />  
        <Route path="/avp/*" element={<GestioneAvpDetailScreen  />} />
        <Route path="/avp/grafo/*" element={<GestioneAvpGrafoScreen  />} />  
        <Route path="/avp/grafo/nodo/arco" element={<GestioneAvpArcoScreen  />} />  
        <Route path="/avp/grafo/nodo/playlistfile" element={<GestioneAvpContentPlaylistFileScreen  />} />  
        <Route path="/avp/istanze" element={<GestioneAvpIstanzeScreen  />} />  
        <Route path="/avp/istanze/*" element={<GestioneAvpIstanzeDetailScreen  />} />          
        <Route path="/utenti" element={<GestioneUtentiScreen  />} />  
        <Route path="/utenti/*" element={<GestioneUtenteDetailScreen  />} />  
        <Route path="/utenti/gruppi" element={<GestioneGruppiUtenteScreen  />} />  
        <Route path="/utenti/gruppi/*" element={<GestioneGruppoUtenteDetailScreen  />} />        
        <Route path="/contenuti" element={<GestioneContenutiScreen  />} />
        <Route path="/contenuti/pick" element={<GestioneContenutiPickScreen />} />
        <Route path="/contenuti/file" element={<GestioneContenutiFileScreen />} />  
        <Route path="/contenuti/avatar" element={<GestioneContenutiAvatarScreen />} />  
        <Route path="/contenuti/folder/*" element={<GestioneContenutiFolderScreen />} />       
        <Route path="/sistema" element={<GestioneSistemaScreen  />} />  
        <Route path="/sistema/*" element={<GestioneSistemaDetailScreen  />} />  
        <Route component={Notfound} />  
      </Routes>  
    </Router> 
  );
}

export default App;
