import React from 'react'
import { useState } from 'react';
import BreadCrumbItem from './BreadCrumbItem';

export default function BreadCrumb({items}) {  

    const renderItems = () => {
        let td = [];
        if (items!=null && items.length>0) {
            for (let i = 0; i<items.length; i++) {
                let title = items[i].title;
                let link = items[i].link;
                let last = i==(items.length-1);
                        
                td.push(<BreadCrumbItem key={"bc"+i} itemTitle={title} itemLink={link} isLast={last} /> );
            }
        }
        return td;
    };

    return (
      <div id="avp-breadcrumb-container" className="clearfix" >
        {renderItems()}
      </div>

    ); 
  
  } 