import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, apiPostAvpArcoAdd, apiGetAvpAssistenteById, apiGetAvpGrafo } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { findNode } from './AvpGrafoUtil';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

export default function GestioneAvpArcoScreen() {  

  const navigate = useNavigate();

  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'Crea/Modifica AVP', link:null },
    { title: 'Grafo AVP', link:null },
    { title: 'Arco', link:null },
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [avp, setAvp] = useState(null)
  const [avpGrafo, setAvpGrafo] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedArc, setSelectedArc] = useState(null);
 
  //FORM
  const [formNodoEndStatus, setFormNodoEndStatus] = useState('')
  const [formTestoStatus, setFormTestoStatus] = useState('')
  const formNodoEndSelect = React.useRef();
  const formTestoInput = React.useRef();



  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {


  }, [selectedArc])



  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    loadAvp();
  }



  function loadAvp() {
    const  url = window.location.pathname;
    const idAvp = getValueByQueryString("idavp");
    
    if (idAvp!=null && idAvp!="") {
      apiGetAvpAssistenteById(idAvp, avpCallback);
    }
  }

  function avpCallback(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      loadGrafo(response.data);
    }
  }  


  function loadGrafo(pAvp) {
    
    if (pAvp!=null) {
      apiGetAvpGrafo(pAvp.idAvp, function(response){
        console.log(" ---> avpGrafo", response.data);
        const idNodo = getValueByQueryString("idnodo");
        if (idNodo!=null) {
          //refresh
          let nodo = findNode(idNodo, response.data.nodi);
          //setSelectedNode(nodo);
          if (nodo!=null && nodo.archi!=null) {
            const idArco = getValueByQueryString("idarco");
            let arco = null;
            for (var i=0; i<nodo.archi.length; i++) {
              if (nodo.archi[i].idArco==idArco) {
                arco = nodo.archi[i];
                break;
              }
            }

            if (arco==null) {
              //new
              arco = {
                formato:"",
                idLingua: (pAvp.lingue!=null && pAvp.lingue.length>0 ? pAvp.lingue[0].idLingua : null),
                idNodoEnd: null,
                idNodoStart: eval(idNodo),
                ordine: 1,
                testo : "",
              };
              breadCrumb[3].title = "Nuovo arco";
            }
            else {
              breadCrumb[3].title = "Arco "+arco.testo;
            }
            breadCrumb[2].link = "/avp/grafo/"+pAvp.idAvp+"?idnodo="+idNodo;

            setCurrentBreadCrumb(breadCrumb);

            setAvp(pAvp);
            setAvpGrafo(response.data);
            setSelectedNode(nodo);
            setSelectedArc(arco);

          }
        }

      });
    }
  }  
 
  function validateForm() {
    console.log("validateForm");
    let valid = true;
    const idNodoEnd = formNodoEndSelect.current.value;
    const testo = formTestoInput.current.value;
    if (testo==null || testo=="") {
        valid = false;
        setFormTestoStatus(" notvalid");
    }
    else {
        setFormTestoStatus("");
    }

    if (idNodoEnd==null || idNodoEnd=="") {
        valid = false;
        setFormNodoEndStatus(" notvalid");
    }
    else {
        setFormNodoEndStatus("");
    }

    return valid;    
} 

function saveArc() {
  apiPostAvpArcoAdd(selectedArc, function(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      setAlertSuccessOpen(true);
      setAlertErrorOpen(false);
      navigate("/avp/grafo/"+avp.idAvp+"?idnodo="+selectedNode.idNodo);
    }
    else {
      setAlertSuccessOpen(false);
      setAlertErrorOpen(true);
    }
  });
}


  function footerCancelCallback() {
    console.log("cancel");

    navigate("/avp/grafo/"+avp.idAvp+"?idnodo="+selectedNode.idNodo);
  }

  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
      const idNodoEnd = eval(formNodoEndSelect.current.value);
      const testo = formTestoInput.current.value;

      selectedArc.testo = testo;
      selectedArc.idNodoEnd = idNodoEnd;

      saveArc();
    }
  }  




  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content "  >
  
          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-gestioneavp-nodearcs-section-title" >
              Gestione Arco
            </div>
          </div>


            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-nodostart" className='required' >Nodo start</label><br/>
                <input id="avp-form-nodostart" type='text' value={selectedNode!=null ? selectedNode.descNodo : ''} disabled={true} />
              </div>  
            </div>

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-nodoend" className='required'>Nodo end</label><br/>
                <select ref={formNodoEndSelect} id="avp-form-nodoend" value={selectedArc!=null ? selectedArc.idNodoEnd : ""}>
                  { 
                    avpGrafo!=null && avpGrafo.nodi!=null && avpGrafo.nodi.length>0 ?
                      avpGrafo.nodi.map((item, i) => { 
                        if (selectedNode!=null && item.idNodo!=selectedNode.idNodo && item.nodoStart!=true) {
                          return (<option key={item.idNodo} value={item.idNodo}  >{item.descNodo}</option>) 
                        }
                        else {
                          return "";
                        }
                        
                      })
                    : (<option></option>)
                      
                    
                  }  
                </select>            
              </div>              
            </div>

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column" style={{"width":"50%"}}>
                <label htmlFor="avp-form-text" className='required' >Testo</label><br/>
                <input ref={formTestoInput} id="avp-form-text" className={formTestoStatus} style={{ "maxWidth":"none"}} type='text' defaultValue={selectedArc!=null ? selectedArc.testo : ''}  />
              </div>  
            </div>


        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
    </div>     
  ); 

} 