
import axios from 'axios';


let apiClient = null;

function getToken() {
    return localStorage.getItem("token");
}

function setToken(token) {
    return localStorage.setItem("token", token);
}

export function getUserAnag() {
    let json = localStorage.getItem("userAnag");
    if (json!=null && json.length>0) {
        let anag = JSON.parse(json);
        return anag;
    }
    else
        return "";
}

function setUserAnag(anag) {
    if (anag!=null) {
        let json = JSON.stringify(anag);
        return localStorage.setItem("userAnag", json);
    }
    else 
        return localStorage.setItem("userAnag", "");
}

export function userIsLogged() {
    let token = getToken();
    if (token!=null) 
        return true;

    return false;
}

function getAPIClient() {
    
    if (apiClient==null) {
        apiClient = axios.create({
            baseURL: "https://avswebapi.bitreloaded.it/api" 
        });

        apiClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status===401) {
                    console.error("401");
                    window.location.href = "/login";
                }
                else
                if (error.code==="ERR_NETWORK") {
                    console.error("ERR_NETWORK");
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            }
        );

        console.log("axios client created");
    }
    else {
        //console.error("axios client NOT created");
    }
    return apiClient;
}

export async function apiAuthenticate(authData, callback) {
    try {
        let client = getAPIClient();
        let response = await client.post('/User/Authenticate', authData);
        let token = response.data.token;
        let userId = response.data.userID;
        let userAnag = null;
        setToken(token);
        apiGetAnaUtente(userId, function(anagResp){
            if (anagResp!=null && anagResp.status>=200 && anagResp.status<400) {
                userAnag = anagResp.data; 
                setUserAnag(userAnag);
                console.log("userAnag:", userAnag);
            }
            if (callback)
                callback(response);            
        });

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
};

export async function apiGetAnaUtenteIndex(soloAttivi, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaUtente/Index/${soloAttivi}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiGetAnaUtente(idUtente, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaUtente/Get/${idUtente}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiGetAnaUtenteByEmail(email, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaUtente/GetByEmail/${email}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiGetAnaUtenteRuoli(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaUtente/GetRuoli`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
};

export async function apiPostAnaUtente(utente, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AnaUtente/Post`, utente, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAnaUtente(idUtente, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AnaUtente/Delete/${idUtente}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiGetAnaGruppoUtenteIndex(soloAttivi, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaGruppoUtente/Index/${soloAttivi}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiGetAnaGruppoUtente(idGruppo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AnaGruppoUtente/Get/${idGruppo}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiPostAnaGruppoUtente(gruppo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AnaGruppoUtente/Post`, gruppo, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAnaGruppoUtente(idGruppo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AnaGruppoUtente/Delete/${idGruppo}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiGetAllLingue(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpAssistente/GetAllLingue`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpAssistente(status, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpAssistente/Index/${status}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpAssistenteById(idAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpAssistente/Get/${idAvp}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostAvpAssistente(avp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpAssistente/Post`, avp, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAvpAssistente(idAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AvpAssistente/Delete/${idAvp}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpGrafo(idAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpAssistente/GetAvpGrafo/${idAvp}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpIstanzeByAvp(idAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/GetByAvpId/${idAvp}/0`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpIstanzaById(idUtenteAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/Get/${idUtenteAvp}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpTipoIstanze(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/GetTipoIstanze`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpQRCodePresets(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/GetQRCodePresets`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpQRCodeStili(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/GetQRCodeStili`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpQRCodeAlign(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpIstanze/GetQRCodeAlign`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostAvpIstanza(istanza, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpIstanze/Post`, istanza, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAvpIstanza(idUtenteAvp, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AvpIstanze/Delete/${idUtenteAvp}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoTipoAvvio(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetTipoAvvio`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodo(idNodo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/Get/${idNodo}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostAvpNodoAdd(nodo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpNodo/AddNodo`, nodo, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAvpNodo(idNodo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AvpNodo/Delete/${idNodo}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoDuplica(idNodo, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/DuplicaNodo/${idNodo}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoTipoAvatar(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetTipoAvatar`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoAllAvatars(tipoAvatar, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetAllAvatars/${tipoAvatar}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoPlaylist(idNodo, idLingua, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetNodePlayList/${idNodo}/${idLingua}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoPlaylistFiles(idNodo, idLingua, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetNodePlayListFiles/${idNodo}/${idLingua}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpNodoAvatar(idNodo, idLingua, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpNodo/GetAvatar/${idNodo}/${idLingua}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostAvpNodoAvatar(avatar, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpNodo/SaveAvatar`, avatar, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiPostAvpArcoAdd(arco, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpArco/AddArco`, arco, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteAvpArco(idArco, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/AvpArco/Delete/${idArco}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpArcoMoveUpArco(idArco, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpArco/MoveUpArco/${idArco}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpArcoMoveDownArco(idArco, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpArco/MoveDownArco/${idArco}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFileGetTipoContenuto(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFile/GetTipoContenuto`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFileGetAllFiles(searchString, tipoContenuto, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFile/GetAllFiles?SearchString=${searchString}&TipoContenuto=${tipoContenuto}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFileGetAllFilesByFolder(idFolder, idGruppoUtente, searchString, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFile/GetAllFilesByFolder/${idFolder}/${idGruppoUtente}/${searchString}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFile(idFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFile/Get/${idFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiPostConFileAdd(confile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/ConFile/AddFile`, confile, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostConFileUpdate(confile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/ConFile/UpdateFile`, confile, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostConFileUpload(idFile, idFolder, idUtente, formData, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
        };
        
        let response = await client.post(`/ConFile/UploadFile/${idFile}/${idFolder}/${idUtente}`, formData, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteConFile(idFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/ConFile/Delete/${idFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpPlaylistContent(idPlaylistFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpPlaylist/GetPlaylistContent/${idPlaylistFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostAvpPlaylistAddPlaylistContent(playListFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/AvpPlaylist/AddPlaylistContent`, playListFile, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpPlaylistMoveUpPlaylistContent(idPlaylistFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpPlaylist/MoveUpPlaylistContent/${idPlaylistFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpPlaylistMoveDownPlaylistContent(idPlaylistFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpPlaylist/MoveDownPlaylistContent/${idPlaylistFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvpPlaylistRemovePlaylistContent(idPlaylistFile, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/AvpPlaylist/RemovePlaylistContent/${idPlaylistFile}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFolderTreeByUser(idUtente, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFolder/GetTreeByUser/${idUtente}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFolder(idFolder, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFolder/Get/${idFolder}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiPostConFolderAdd(folder, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/ConFolder/Post`, folder, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteConFolder(idFolder, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/ConFolder/Delete/${idFolder}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetConFolderRipristina(idFolder, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/ConFolder/Ripristina/${idFolder}/0`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvatarGenere(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Avatar/GetGenere`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvatarLanguages(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Avatar/GetLanguages`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvatarVoices(idLingua, idGenere, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Avatar/GetVoices/${idLingua}/${idGenere}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiGetAvatarVoice(provider, idVoce, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Avatar/GetVoice/${provider}/${idVoce}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiPostCreateAvatar(avatar, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/Avatar/CreateAvatar`, avatar, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};


export async function apiGetSysSettings(callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/SysSetting`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiGetSysSetting(idSetting, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/SysSetting/${idSetting}`, config);

        if (callback)
            callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
}; 

export async function apiPostSysSetting(setting, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.post(`/SysSetting`, setting, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiDeleteSysSetting(idSetting, callback) {
    try {
        let client = getAPIClient();
        const token = getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.delete(`/SysSetting/${idSetting}`, config);

        if (callback)
            callback(response);

    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

