
export const NODE_SIZE = 70;
const NODE_SPACE_X = 80;
const NODE_SPACE_Y = 80;
const NODE_HALFSIZE = NODE_SIZE/2;
const ARC_WIDTH = 2;
const ARC_ARROW_SIZE = 16;
const ARC_COLOR = "#000000";
const ARC_INV_COLOR = "#0000AA";
const BKG_COLOR = "#7F7F7F";

let clonedData = null;
let normData = null;
let maxTreeLevels = 0;
let lastOffsetForLevel = [];
let totalWeight = 0;
let totalSizeW = 0;
let totalSizeH = 0;
let selectedNode = null;
let data = null;

export function drawGraphToCanvas(context, container, graphData, graphSelectedNode, canvasScale) {

    data = graphData;
    selectedNode = graphSelectedNode;

    
    normalizeGrafo();
    totalWeight = calculateWeight(normData);
    totalSizeW = totalWeight * (NODE_SIZE+NODE_SPACE_X);
    totalSizeH = (maxTreeLevels+1) * (NODE_SIZE+NODE_SPACE_Y);
    console.log(normData);
    console.log("maxTreeLevels="+maxTreeLevels+"   totalWeight="+totalWeight);
    console.log("totalSizeW="+totalSizeW+"   totalSizeH="+totalSizeH);
    console.log("canvasScale="+canvasScale);

   
    processNodeRecursive(context, normData, null, NODE_SIZE);
    //fitToContainer(context, container);
    context.canvas.width = totalSizeW * canvasScale;
    context.canvas.height = totalSizeH * canvasScale;
    clearCanvas(context);
    context.setTransform(canvasScale, 0, 0, canvasScale, 0, 0);

    //DRAW
    lastOffsetForLevel = [];
    drawGrafo(context, clonedData);

}

function fitToContainer(context, container) {

    
    console.log("canvas CONT W="+container.clientWidth+",  H="+container.clientHeight);
    let containerWidth = container.clientWidth;
    let containerHeight = container.clientHeight;

    context.canvas.width = containerWidth;
    context.canvas.height = containerHeight; 
/*
    context.canvas.width = totalSizeW;
    context.canvas.height = totalSizeH;
*/
    console.log("canvas W="+context.canvas.width+",  H="+context.canvas.height);

};


function clearCanvas(context) {
    context.fillStyle = BKG_COLOR;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
}




function drawGrafo(context, clonedData) {
    drawArcs(context, clonedData);
    drawNodes(context, clonedData);
}

function drawNodes(context, clonedData) {
    if (clonedData!=null && clonedData.length>0) {
      for (var i=0; i<clonedData.length; i++) {
        let nodo = clonedData[i];
        drawNode(context, nodo, NODE_SIZE)
      }
    }
}

function drawArcs(context, clonedData) {
    if (clonedData!=null && clonedData.length>0) {
      for (var i=0; i<clonedData.length; i++) {
        let nodo = clonedData[i];
        if (nodo.archi!=null && nodo.archi.length>0) {
          for (var j=0; j<nodo.archi.length; j++) {
            let arc = nodo.archi[j];
            if (arc!=null) {
              let endNode = findNodo(arc.idNodoEnd);
              drawArc(context, arc, nodo, endNode);
            }            
          }
        }
      }
    }
}


  function drawNode(context, node, nodeSize) {
    
    if (node!=null) {
      let fillColor = "#514429";
      let borderColor = "#916615";
      let textColor = "#FFFFFF";
      let label = "";
      let borderSize = 4;

      if (selectedNode!=null && node.idNodo===selectedNode.idNodo) {
        label = node.descNodo;
        fillColor = "#897C60";
        borderColor = "#FFFFFF";
        borderSize = 8;        
      }
      else {
        label = node.descNodo;
      }

      if (node.nodoStart) {
        label = "START";
        fillColor = "#DE9300";
        borderColor = "#916615";
      }

      context.beginPath();
      //context.fillRect(node.posX, node.posY, NODE_HALFSIZE*3, NODE_HALFSIZE*1.5);
      context.arc(node.posX+NODE_HALFSIZE, node.posY+NODE_HALFSIZE, NODE_HALFSIZE, 0, 2 * Math.PI);
      context.fillStyle = fillColor;
      context.fill();
      context.lineWidth = borderSize;
      context.strokeStyle = borderColor;
      context.stroke();
      
      context.save();

      context.beginPath();

      context.rect(node.posX,node.posY,NODE_SIZE,NODE_SIZE);
      context.font = "12px Arial";
      context.fillStyle = textColor;
      context.textAlign = "center";
      context.fillText(label,node.posX+NODE_HALFSIZE,node.posY+NODE_HALFSIZE+6);
      context.clip();

      context.closePath();

      context.restore();

    }
  }


  function drawArc(context, arc, startNode, endNode) {
    if (arc!=null && startNode!=null && endNode!=null) {
      // Start a new Path
      //canvas_arrow(context, startNode.posX+NODE_HALFSIZE, startNode.posY+NODE_HALFSIZE, endNode.posX+NODE_HALFSIZE, endNode.posY+NODE_HALFSIZE);
      
      context.lineWidth = ARC_WIDTH;
      context.lineJoin = 'butt';



      if (endNode.nodeLevel>=startNode.nodeLevel) {
        let x1 = startNode.posX+NODE_HALFSIZE;
        let y1 = startNode.posY+NODE_HALFSIZE;
        let x2 = endNode.posX+NODE_HALFSIZE;  
        let y2 = endNode.posY+NODE_HALFSIZE;

        context.strokeStyle = ARC_COLOR;
        context.fillStyle = ARC_COLOR; // for the triangle fill
        //draw straight line
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();

              /*
        // draw the starting arrowhead
        var startRadians=Math.atan((y2-y1)/(x2-x1));
        startRadians+=((x2>x1)?-90:90)*Math.PI/180;
        console.log(startRadians);
        if (startRadians==Math.PI) startRadians = 0;
        drawArrowhead(context,x1,y1,startRadians);
        */

        // draw the ending arrowhead
        var endRadians=Math.atan((y2-y1)/(x2-x1));
        endRadians+=((x2>x1)?90:-90)*Math.PI/180;
        if (endRadians===0) endRadians = Math.PI;

        x2 = x2 - Math.sin(endRadians)*NODE_HALFSIZE;
        y2 = y2 + Math.cos(endRadians)*NODE_HALFSIZE;

        drawArrowhead(context,x2,y2,endRadians);
      }
      else {
        let x2 = startNode.posX+NODE_HALFSIZE;
        let y2 = startNode.posY+NODE_HALFSIZE;
        let x1 = endNode.posX+NODE_HALFSIZE;  
        let y1 = endNode.posY+NODE_HALFSIZE;

        context.strokeStyle = ARC_INV_COLOR;
        context.fillStyle = ARC_INV_COLOR; // for the triangle fill
        //draw arc
        let sx = x1;
        let sy = y1;
        let px1 = x1 - ((x1-x2) / 2);
        let py1 = y1;
        let px2 = x2;
        let py2 = y2 - ((y2-y1) / 2);
        let dx = x2;
        let dy = y2-NODE_HALFSIZE;
        context.beginPath();
        context.moveTo(sx, sy);
        context.bezierCurveTo(px1, py1, px2, py2, dx, dy);
        context.stroke();      
      }

    }
  }


function drawArrowhead(ctx,x,y,radians){
    ctx.save();
    ctx.beginPath();
    ctx.translate(x,y);
    ctx.rotate(radians);
    ctx.moveTo(0,0);
    ctx.lineTo( (ARC_ARROW_SIZE/2),ARC_ARROW_SIZE);
    ctx.lineTo(-(ARC_ARROW_SIZE/2),ARC_ARROW_SIZE);
    ctx.closePath();
    ctx.restore();
    ctx.fill();
}


function processNodeRecursive(context, node, parentNode, nodeSize) {
    
    if (node!=null) {
      if (parentNode!=null) {
        if (parentNode.children!=null && parentNode.children.length>1) {
          if ( isNaN(lastOffsetForLevel[node.nodeLevel]) ) {
            //console.log(node.descNodo+"  prevOffLev=0");
            lastOffsetForLevel[node.nodeLevel] = node.weight;
          }
          else {
            //console.log(node.descNodo+"  prevOffLev="+lastOffsetForLevel[node.nodeLevel]);
            lastOffsetForLevel[node.nodeLevel] += node.weight;
          }
          node.offsetForLevel = lastOffsetForLevel[node.nodeLevel]; 
          //console.log(node.descNodo+"  nextOffLev="+node.offsetForLevel);       
        }
        else {
          //console.log(node.descNodo+"  get parent offLev=");       
          node.offsetForLevel = parentNode.offsetForLevel;
          lastOffsetForLevel[node.nodeLevel]++;
        }
      }
      else {
        node.offsetForLevel = totalWeight;
      }

     
      calculateNodePos(context, node, nodeSize);
      if (node.children!=null && node.children.length>0) {
        for (var i=0; i<node.children.length; i++) {
          processNodeRecursive(context, node.children[i], node, nodeSize);
        }
      }
    }
    else
      console.log("processNodeRecursive NO NODE");
  }

  function calculateNodePos(context, node, nodeSize) {
    if (node!=null) {
      let offsetX = (node.offsetForLevel-node.weight)*(NODE_SIZE+NODE_SPACE_X);
      let cX = ((node.weight / totalWeight) * totalSizeW) / 2;
      let posX = offsetX + cX - NODE_HALFSIZE;
      let posY = node.nodeLevel>0 ? (node.nodeLevel) * (nodeSize+NODE_SPACE_Y) : nodeSize;    
      node.posX = posX;
      node.posY = posY;
      //console.log( node.descNodo+"    posX="+posX+"   posY="+posY +"   offL="+node.offsetForLevel);
    }
  }



  function normalizeGrafo() {
    console.log("normalizeGrafo start");
    maxTreeLevels = 0;
    if (data!=null && data.nodi!=null) {
      clonedData = data.nodi.slice();
      console.log(clonedData);
      if (clonedData!=null && clonedData.length>0) {
        normData = clonedData[0];
        if (normData!=null) {
          normData.nodeLevel = 0;
          normalizeRecursive(normData);
        }
      }
    }
    else {
      console.log("normalizeGrafo no data");
    } 
 
  }

  function calculateWeight(nodo) {
    let weight = 0;
    if (nodo!=null) {
      if (nodo.children!=null && nodo.children.length>0) {
        for (var i=0; i<nodo.children.length; i++) {
          weight = weight + calculateWeight(nodo.children[i]); 
        }
      }
      else {
        weight = 1;
      }
      nodo.weight = weight;
    }
    return weight;
  }

  function normalizeRecursive(nodo) {
    if (nodo!=null) {
      let nNode = normalizeNodo(nodo);
      if (nNode.children!=null && nNode.children.length>0) {
        for (var i=0; i<nNode.children.length; i++) {
          normalizeRecursive(nNode.children[i]);
        }
      }
      if (nNode.nodeLevel>maxTreeLevels) {
        maxTreeLevels = nNode.nodeLevel;
      }
    }
  }

  function normalizeNodo(nodo) {
    if (nodo!=null) {
      if (nodo.archi!=null && nodo.archi.length>0) {
        for (var i=0; i<nodo.archi.length; i++) {
          let arc = nodo.archi[i];
          if (arc!=null) {
            let nodoEnd = findNodo(arc.idNodoEnd);
            if (nodoEnd!=null) {
              if (!(nodoEnd.hasOwnProperty("processedAsChildren") && nodoEnd.processedAsChildren===true ) ) {
                nodoEnd.processedAsChildren = true;
                nodoEnd.nodeLevel = nodo.nodeLevel + 1;
                //add as children
                if (nodo.children==null) {
                  nodo.children = [];
                }
                nodo.children.push(nodoEnd);
              }
            }
            else {
              console.warn("normalizeNodo end node not found with id "+arc.idNodoEnd);
            }
          }
          else {
            console.warn("normalizeNodo arco is null");
          }
        }
      }
      else {
        //foglia
        //console.log("normalizeNodo no archi found for "+nodo.idNodo);
      }
    }
    else {
      console.warn("normalizeNodo nodo is null");
    }
    return nodo;
  }

export function findNodo(idNodo) {
    let result = null;
    if (data!=null && data.nodi!=null && data.nodi.length>0) {
      for (var i=0; i<data.nodi.length; i++) {
        let nodo = data.nodi[i];
        if (nodo.idNodo===idNodo) {
          result = nodo;
          break;
        }
      }
    }
    return result; 
  }  

  export function nodeInCoord(x, y, canvasScale) {
    let result = null;

    if (clonedData!=null && clonedData.length>0) {
      if (isNaN(canvasScale)) canvasScale = 1;
      let sW = NODE_SIZE * canvasScale;
      let sH = NODE_SIZE * canvasScale;
      for (var i=0; i<clonedData.length; i++) {
        let nodo = clonedData[i];
        let nX = nodo.posX * canvasScale;
        let nY = nodo.posY * canvasScale;
        //console.log("nodo.posX="+nodo.posX+"  px="+nX+"  scale="+canvasScale)

        if (
          ( x>=(nX) && x<=(nX+sW) ) &&
          ( y>=(nY) && y<=(nY+sH) ) 
          ){
          result = nodo;
          break;
        }
      }
    }    
    return result;
  }

