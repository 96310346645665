import React from 'react'
import { useState, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { drawGraphToCanvas, nodeInCoord, NODE_SIZE } from './AvpGrafoCanvasUtil';

export default function AvpGrafo({data, pNode, addCallback, duplicateCallback, deleteCallback, selectedCallback}) {  

  const canvasContRef = useRef(null)
  const canvasRef = useRef(null)
  const [canvasCursor, setCanvasCursor] = useState("pointer");
  const [canvasScale, setCanvasScale] = useState(1);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeToolbarStatus, setNodeToolbarStatus] = useState(null);
  
  
  const mouse = {
    isDown: false,
    isUp: false,
    isPressed: false,
    x: 0,
    y: 0
  }
  
  const pos = {
    x: 0,
    y: 0
  }
  
  const trans = {
      x: 0,
      y: 0
  } 


  // mouse drag related variables
  let isDown = false;
  let startX,startY;
  // the accumulated horizontal(X) & vertical(Y) panning the user has done in total
  let netPanningX=0;
  let netPanningY=0;


  useEffect(() => {

    if (pNode!=null) {
      setSelectedNode(pNode);
    }
    else {
      setNodeToolbarStatus(false);
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d')

    drawGraphToCanvas(context, canvasContRef.current, data, selectedNode ,canvasScale);

    assignEventListener(canvas, canvasScale);

  }, [data, selectedNode, canvasScale])

  




	function assignEventListener(canvas, myscale) {

    function clickListener(e) {
      //console.log("click at X="+e.offsetX+"  y="+e.offsetY);
      let clickX = e.offsetX;
      let clickY = e.offsetY;
      var node = nodeInCoord(clickX, clickY, myscale);
      if (node!=null) {
        //console.log(node);
      
        setSelectedNode(node);
        if (selectedCallback!=null) 
          selectedCallback(node);  

        if (node.nodoStart==false) {
          //SHOW TOOLBAR
          let nodeSize = NODE_SIZE * canvasScale;
          let nodeX = node.posX * canvasScale;  
          let nodeY = node.posY * canvasScale;   
          setNodeToolbarStatus(
            {
              posX : (nodeX + (nodeSize/2) - 73) ,
              posY : (nodeY + nodeSize)
            }
          );
        }
        else {
          setNodeToolbarStatus(null);
        }

      }
      else {
        setSelectedNode(null);
        if (selectedCallback!=null) 
          selectedCallback(null);  

        setNodeToolbarStatus(null);
      }      

    
    }

    canvas.removeEventListener("click", clickListener);
		canvas.addEventListener("click", clickListener); 	


  

	}

  function zoomin() {
    let scale = canvasScale + 0.1;
    console.log("zoomin "+scale);
    setCanvasScale(scale);
    setNodeToolbarStatus(null);
  }
  
  function zoomout() {
    let scale = canvasScale - 0.1;
    console.log("zoomout "+scale);
    setCanvasScale(scale);
    setNodeToolbarStatus(null);
  }
/*  
  function moveleft() {
    clearcanvas();
    ctx.translate(-10, 0);
    drawcanvas();
  }
  
  function moveright() {
    clearcanvas();
    ctx.translate(10, 0);
    drawcanvas();
  }
*/

  function handleAdd() {
    if (addCallback!=null) {
      addCallback(selectedNode);
    }
  } 
  function handleDuplicate() {
    if (duplicateCallback!=null) {
      duplicateCallback(selectedNode);
    }
  } 
  function handleDelete() {
    if (deleteCallback!=null) {
      deleteCallback(selectedNode);
    }
  } 

    return (
      <div id="avp-grafo-canvas-container" ref={canvasContRef} >
          <canvas id="avp-grafo-canvas" ref={canvasRef} style={{cursor: canvasCursor}}></canvas>
          <IconButton color="#ffffff" aria-label="zoom in" id="avp-grafo-zoomin-button" onClick={()=>zoomin()}>
              <ZoomIn />
          </IconButton>
          <IconButton color="#ffffff" aria-label="zoom out" id="avp-grafo-zoomout-button" onClick={()=>zoomout()} >
              <ZoomOut />
          </IconButton>
          <div id="avp-grafo-nodetoolbar" style={{ "display" : (nodeToolbarStatus!=null && nodeToolbarStatus.posX!=null ? "block" : "none"), "top": (nodeToolbarStatus!=null ? nodeToolbarStatus.posY : 0), "left": (nodeToolbarStatus!=null ? nodeToolbarStatus.posX : 0) }}  >
            <img src="/images/add_node_btn.svg" onClick={handleAdd}  />
            <img src="/images/duplicate_node_btn.svg" onClick={handleDuplicate}  />
            <img src="/images/delete_btn.svg" onClick={handleDelete}  />
          </div>
      </div>
      

    ); 
  
  } 