import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, apiGetAvpAssistenteById, apiGetAllLingue, apiPostAvpAssistente, apiPostAvpNodoAdd, apiPostAvpArcoAdd } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


export default function GestioneAvpDetailScreen() {  

  const navigate = useNavigate();

  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const [avp, setAvp] = useState([])
  const [avpLingue, setAvpLingue] = useState([])

  //FORM
  const [formAvpDescrStatus, setFormAvpDescrStatus] = useState('')
  const [formAvpCodAssetStatus, setFormAvpCodAssetStatus] = useState('')
  const [formAvpTimeoutStatus, setFormAvpTimeoutStatus] = useState('')
  const [formAvpMaxInstStatus, setFormAvpMaxInstStatus] = useState('')
  const formAvpDescrInput = React.useRef();
  const formAvpCodAssetInput = React.useRef();
  const formAvpTimeoutInput = React.useRef();
  const formAvpMaxInstInput = React.useRef();


  const breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'Crea/Modifica AVP', link:null },
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }

  function initPage() {
    console.log("initPage");

    loadLingue();
  }

  function loadLingue() {
    console.log("loadLingue");

    apiGetAllLingue( function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAvpLingue(response.data);
      }
      loadAvp();
    });

  }

  function stopPage() {
    console.log("stopPage");

  }

  function loadAvp() {
    const  url = window.location.pathname;
    const idAvp = url.substring(url.lastIndexOf('/') + 1);
    
    if (idAvp!=null && idAvp!="" && idAvp!=="0") {
      apiGetAvpAssistenteById(idAvp, avpCallback);
    }
    else {
      let tmpAvp = {
        descAssistente: "Nuovo",
        codiceAsset: "",
        timeout: 600,
        nistanze: 1,
        formato: "string",
        sysUser: "",
        sysDate: new Date().toISOString(),
        sysAttivo: true,
        lingue: [
          {
            idLingua: 1,
            codLingua: "it-IT",
            descrizione: "Italiano",
            linguaDefault: true
          }
        ]
      }      
      setAvp(tmpAvp);
    }
  }

  function avpCallback(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      console.log("AvpAssistente", response.data);
      setAvp(response.data);
    }
  }

  function avpHasLingua(idLingua) {
    let found = false;
    if (avp!=null && avp.lingue!=null && avp.lingue.length>0) {
      for (var i=0; i<avp.lingue.length; i++) {
        if (avp.lingue[i].idLingua==idLingua) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  function handleDescrizioneField(event) {
    let value = event.target.value;
    avp.descAssistente = value;
    let newObj = Object.assign({}, avp);
    setAvp(newObj);     
  }

  function handleCodiceAssetField(event) {
    let value = event.target.value;
    avp.codiceAsset = value;
    let newObj = Object.assign({}, avp);
    setAvp(newObj);     
  }  

  function handleTimeoutField(event) {
    let value = event.target.value;
    avp.timeout = eval(value);
    let newObj = Object.assign({}, avp);
    setAvp(newObj);     
  }    

  function handleMaxIstanzeField(event) {
    let value = event.target.value;
    avp.nistanze = eval(value);
    let newObj = Object.assign({}, avp);
    setAvp(newObj);     
  }  
  
  function handleLinguaClick(event) {
    let isChecked = event.target.checked;
    let idLingua  = event.target.dataset.idlingua;
    console.log("lingua "+idLingua+"="+isChecked);
    if (isChecked) {
      //add lingua
      if  (avp!=null && avpLingue!=null && avpLingue.length>0) {
        let newLingua = null;
        for (var i=0; i<avpLingue.length; i++) {
          if (avpLingue[i].idLingua==idLingua) {
            newLingua = avpLingue[i];
            break;
          }
        }
        if (newLingua!=null) {
          if (avp.lingue==null) {
            avp.lingue = [];
          }
          avp.lingue.push(newLingua);
          let newObj = Object.assign({}, avp);
          setAvp(newObj);            
        }
      }
    }
    else {
      //remove
      let indexToRemove = -1;
      if  (avp!=null && avp.lingue!=null && avp.lingue.length>0) {
        for (var i=0; i<avp.lingue.length; i++) {
          if (avp.lingue[i].idLingua==idLingua) {
            indexToRemove = i;
            break;
          }
        }
      }
      if (indexToRemove!=-1) {
        avp.lingue.splice(indexToRemove, 1);
        let newObj = Object.assign({}, avp);
        setAvp(newObj);         
      }
    }

  }

  function openGrafoCallback() {
    if (avp!=null) {
      navigate("/avp/grafo/"+avp.idAvp);
    }
  }  

  function validateForm() {
    console.log("validateForm");
    let valid = true;
    const descr = formAvpDescrInput.current.value;
    const codeasset = formAvpCodAssetInput.current.value;
    const timeout = formAvpTimeoutInput.current.value;
    const maxinst = formAvpMaxInstInput.current.value;

  
    if (descr==null || descr=="") {
        valid = false;
        setFormAvpDescrStatus(" notvalid");
    }
    else {
        setFormAvpDescrStatus("");
    }

    if (codeasset==null || codeasset=="") {
        valid = false;
        setFormAvpCodAssetStatus(" notvalid");
    }
    else {
        setFormAvpCodAssetStatus("");
    }

    if (timeout==null || timeout=="" || isNaN(timeout)) {
      valid = false;
      setFormAvpTimeoutStatus(" notvalid");
    }
    else {
      setFormAvpTimeoutStatus("");
    }    

    if (maxinst==null || maxinst=="" || isNaN(maxinst)) {
      valid = false;
      
      setFormAvpMaxInstStatus(" notvalid");
    }
    else {
      setFormAvpMaxInstStatus("");
    }        

    return valid;    
} 

  function handleEdit() {

    if (avp!=null) {
      if (validateForm()) {
        const descr = formAvpDescrInput.current.value;
        const codeasset = formAvpCodAssetInput.current.value;
        const timeout = formAvpTimeoutInput.current.value;
        const maxinst = formAvpMaxInstInput.current.value;
        
        avp.descAssistente = descr;
        avp.codiceAsset = codeasset;
        avp.timeout = eval(timeout);
        avp.nistanze = eval(maxinst);
   
        apiPostAvpAssistente( avp, function(response) {
          if (response!=null && response.status>=200 && response.status<400) {
            setAlertSuccessOpen(true);
            setAlertErrorOpen(false);
            navigate("/avp");
          }
          else {
            setAlertSuccessOpen(false);
            setAlertErrorOpen(true);
          }
        }); 
      }

    }
  
  }  

  function handleNew() {
    if (avp!=null) {
      if (validateForm()) {
        const descr = formAvpDescrInput.current.value;
        const codeasset = formAvpCodAssetInput.current.value;
        const timeout = formAvpTimeoutInput.current.value;
        const maxinst = formAvpMaxInstInput.current.value;
        
        avp.descAssistente = descr;
        avp.codiceAsset = codeasset;
        avp.timeout = eval(timeout);
        avp.nistanze = eval(maxinst);

        apiPostAvpAssistente( avp, function(response) {
          if (response!=null && response.status>=200 && response.status<400) {
            setAvp(response.data);
            createStartNode(response.data);
          }
          else {
            setAlertSuccessOpen(false);
            setAlertErrorOpen(true);
          }
        });           
      }
    }

  }
  
  function createStartNode(newAvp) {
    let tmpNode = {
      idNodo: 0,
      idAvp: newAvp.idAvp,
      descNodo: "START",
      nodoStart: true,
      tipoAvvio: 0,
      apiEsterna: "",
      formato: "",
      flagGoBack: false,
      sysUser: "",
      sysDate: new Date().toISOString(),
      sysAttivo: true
    };
    apiPostAvpNodoAdd(tmpNode, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        createRootNode(newAvp, response.data);
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }      
    });  
  }

  function createRootNode(newAvp, startNode) {
    if (startNode!=null) {
      let tmpNode = {
        idAvp: newAvp.idAvp,
        descNodo: "ROOT",
        nodoStart: false,
        tipoAvvio: 1,
        apiEsterna: "",
        formato: "",
        flagGoBack: false,
        sysUser: "",
        sysDate: new Date().toISOString(),
        sysAttivo: true
      };
      apiPostAvpNodoAdd(tmpNode, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          let newNode = response.data;
          if (newNode!=null) {
            let tmpArco = {
              formato:"",
              idLingua: (newAvp.lingue!=null && newAvp.lingue.length>0 ? newAvp.lingue[0].idLingua : null),
              idNodoStart: startNode.idNodo,
              idNodoEnd: newNode.idNodo,
              ordine: 1,
              testo : "arcoroot",
            };      
            apiPostAvpArcoAdd(tmpArco, function(aResponse) {
              if (aResponse!=null && aResponse.status>=200 && aResponse.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
                navigate("/avp");
              }
              else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
              }  
            });         
          }
        }
      });
    }
  }

  function footerCancelCallback() {
    console.log("cancel");
    navigate("/avp");
  }

  function footerSaveCallback() {
    console.log("save");

    if (avp!=null) {
      if (avp.idAvp!=null && avp.idAvp!=="undefined") {
        handleEdit();
      }
      else {
        handleNew();
      }
    }

    
  }


  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={breadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 

        <div className="avp-cms-screen-title">Dati AVP</div>
        <div className="avp-cms-screen-content"  >

          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-descr" className='required' >Descrizione</label><br/>
              <input ref={formAvpDescrInput} id="avp-form-descr" type='text' className={formAvpDescrStatus} value={avp!=null ? avp.descAssistente : ''} defaultValue="" onChange={handleDescrizioneField} />
            </div>  
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-codeasset" className='required'>Codice asset</label><br/>
              <input ref={formAvpCodAssetInput} id="avp-form-codeasset" type='text' className={formAvpCodAssetStatus} value={avp!=null ? avp.codiceAsset : ''} defaultValue="" onChange={handleCodiceAssetField} />              
            </div>              
          </div>
          
          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-timeout" className='required'>Timeout</label><br/>
              <input ref={formAvpTimeoutInput} id="avp-form-timeout" type='number' className={formAvpTimeoutStatus} value={avp!=null ? avp.timeout : '600'} defaultValue="600" onChange={handleTimeoutField} />
            </div>  
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-maxistances" className='required'>Istanze concorrenti</label><br/>
              <input ref={formAvpMaxInstInput} id="avp-form-maxistances" className={formAvpMaxInstStatus} type='number' min="1" value={avp!=null ? avp.nistanze : '1'} defaultValue="1" onChange={handleMaxIstanzeField}  />              
            </div>              
          </div>

          <div className="avp-cms-screen-section-title"  >Lingue</div>
          { 
            avpLingue!=null && avpLingue.length>0 ?
            avpLingue.map((item, i) => { 
                let inputId = "avp-form-lingua"+item.idLingua;
                let isChecked = avpHasLingua(item.idLingua);
                let checkedValue = isChecked ? 'checked' : '';
                return (
                  <div key={item.idLingua} className="avp-cms-screen-content-row clearfix" style={{"margin-bottom":"10px"}}>
                    <input id={inputId}  data-idlingua={item.idLingua} type="checkbox" checked={checkedValue} onChange={handleLinguaClick} />            
                    <label for={inputId} >{item.descrizione}</label>
                  </div>                
                ) 
                
              })
            : (<div></div>)
              
            
          }  
          <div className="avp-cms-screen-content-row clearfix"  >&nbsp;</div>

          <div className="avp-cms-screen-content-row clearfix" style={{"display": (avp.idAvp!=null && avp.idAvp!=="undefined" ? "block" : "none") }} >
            <button onClick={()=>openGrafoCallback()}  >GRAFO</button>
          </div>
          
          <div className="avp-cms-screen-content-row clearfix"  >&nbsp;</div>
          
        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}/>
    </div>     
  ); 

} 