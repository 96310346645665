import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getUserAnag } from "../manager/api_manager";

export default function AvpHeader({selectedMenuId, menuCallback}) {  


  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  useEffect(() => {
    let userAnag = getUserAnag();
    if (userAnag!=null) {
      setUsername(userAnag.emailUtente);
    }

  }, [])

  function handleLogout() {
    console.log("handleLogout");

    localStorage.removeItem("token");
    navigate("/");
  } 

  //<li><a className={selectedMenuId=='dashboard' ? 'avp-menu-link-attivo' : ''} href="/">DASHBOARD</a></li>

    return (
      <div id="avp-header-container" className="clearfix" >
        <div id="avp-header-container-left">
          <div id="avp-header-logo-container">
              <img id="avp-header-logo-img" src="/images/logo.svg" alt="Logo"  />
          </div>
          <div id="avp-header-menu-modulo">
              <nav className="navbar navbar-default navbar-custom navbar-home">
                  <ul id="avp-menu-desktop">
                    <li><a className={selectedMenuId=='avp' ? 'avp-menu-link-attivo' : ''} href="/avp">GESTIONE AVP</a></li>
                    <li><a className={selectedMenuId=='contenuti' ? 'avp-menu-link-attivo' : ''} href="/contenuti">CONTENUTI</a></li>
                    <li><a className={selectedMenuId=='utenti' ? 'avp-menu-link-attivo' : ''} href="/utenti">GESTIONE UTENTI</a></li>
                    <li><a className={selectedMenuId=='sistema' ? 'avp-menu-link-attivo' : ''} href="/sistema">SISTEMA</a></li>
                  </ul>
              </nav>
          </div>
        </div> 
        
        <div id="avp-header-container-right">
          <div id="avp-header-logout" onClick={()=>handleLogout()}  >
              <img id="avp-header-logout-img" src="/images/logout_icon.svg" alt="Logout"  />
          </div>
          <div id="avp-header-divider"> 
            <img id="avp-header-menu-right-img" src="/images/vertical_divider.svg" alt="divider"  /> 
          </div>   
          <div id="avp-header-profilo-box">  
              <a  href="#">
                <img id="avp-header-profilo-img" src="/images/user_icon.png" alt="Profilo"  />
              </a>
              <span id="avp-header-profilo-name" >{username}</span>      
          </div>
        </div>

      </div>

    ); 
  
  } 