import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { apiAuthenticate } from "../shared/manager/api_manager";
import { heIL } from '@mui/material/locale';
import { Height } from '@mui/icons-material';

import './login.css';

export default function LoginScreen() {  

    const navigate = useNavigate();

    const [usernameStatus, setUsernameStatus] = useState('')
    const [passwordStatus, setPasswordStatus] = useState('')

    const usernameInput = React.useRef();
    const passwordInput = React.useRef();

    function handleLogin() {
        console.log("handleLogin");

        if (validateForm()) {
            const username = usernameInput.current.value;
            const password = passwordInput.current.value;

            const authData = {
                "emailUtente": username,
                "passwordHash": password,
                "sysUser": "string",
                "sysDate": "2024-05-11T08:35:46.322Z",
                "sysAttivo": true
            };  

            
            //apiAuthenticate(getAPIClient(), authData, apiAuthCallback);    
            apiAuthenticate(authData, apiAuthCallback);    

        }

    }   

    function validateForm() {
        console.log("validateForm");
        let valid = true;
        const username = usernameInput.current.value;
        const password = passwordInput.current.value;
        if (username==null || username=="") {
            valid = false;
            setUsernameStatus(" notvalid");
        }
        else {
            setUsernameStatus("");
        }

        if (password==null || password=="") {
            valid = false;
            setPasswordStatus(" notvalid");
        }
        else {
            setPasswordStatus("");
        }

        return valid;    
    } 

    function apiAuthCallback(response) {
        console.log(response);
        if (response!=null && response.status>=200 && response.status<400) {
            navigate("/avp");
        }
        else {
            console.error("apiAuthCallback");
        }
    }    

    function handlePasswordLost() {
        console.log("handlePasswordLost");
        /*
        if (restartCallback!=null) {
            restartCallback();
        }
            */
    }   

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            handleLogin();
          }
    }  

  return (
    <div id="avp-login-screen"  >
        <div id="avp-login-screen-content"  >
            <div id="avp-login-screen-body-header">
                <div id="avp-login-screen-body-header-title">Assistente Virtuale Personalizzabile</div>
                <div id="avp-login-screen-body-header-subtitle">Content Management System</div>
            </div>
            <div className="avp-login-form-container">
                <div className="avp-login-form">
                    <div className="avp-login-form-title" >Accedi all'area riservata</div> 

                    <div className="avp-login-form-content">
                        <div className="avp-login-elemento">
                            <label className="avp-login-label" htmlFor="avp-login-username" >Username</label>
                            <input className={"avp-login-input" + usernameStatus} type="text" id="avp-login-username" ref={usernameInput} />
                        </div> 

                        <div className="avp-login-elemento">
                            <label className="avp-login-label" htmlFor="avp-login-password" >Password</label>
                            <input className={"avp-login-input" + passwordStatus} type="password" id="avp-login-password" ref={passwordInput} onKeyDown={handleKeyDown} />
                        </div> 

                        <div id="avp-login-errore" className="avp-login-elemento"></div>

                        <div className="avp-login-elemento" id="avp-login-passwordlost" >
                            <a className="avp-login-password-dimenticata" onClick={()=>handlePasswordLost()}  >Hai dimenticato la password?</a>
                        </div>

                        <div className="avp-login-elemento">
                            <button className="avp-login-button" onClick={()=>handleLogin()} >LOGIN</button>
                        </div>

                    </div>  
                </div>
            </div> 
        </div>    
    </div>

  ); 

} 