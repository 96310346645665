import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import BreadCrumb from '../shared/header/BreadCrumb';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { userIsLogged, apiDeleteConFile, apiGetConFileGetTipoContenuto, apiGetConFileGetAllFilesByFolder, apiDeleteConFolder, apiGetConFolderRipristina } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getValueByQueryString } from '../shared/manager/util';
import GestioneContenutiTree from './GestioneContenutiTree';

export default function GestioneContenutiScreen() {  

  const navigate = useNavigate();
  const [tipiContenuto, setTipiContenuto] = useState([])
  const [rows, setRows] = useState([])
  const [searchStringFilter, setSearchStringFilter] = useState("");
  const [tipoContenutoFilter, setTipoContenutoFilter] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idFolderToDelete, setIdFolderToDelete] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  const breadCrumb = [
    { title: 'Gestione contenuti', link:null },

  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {

    console.log(rows);
  }, [rows, selectedFolder])


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    setPageTitle("Gestisci i contenuti");

    apiGetConFileGetTipoContenuto(function(response) {
        let tipiList = [];
        if (response!=null && response.status>=200 && response.status<400) {
            tipiList = response.data; 
            setTipiContenuto(tipiList);
        }
    });


  }

  function getTipoFileDesc(idTipoFile) {
    let desc = "";
    if (tipiContenuto!=null && tipiContenuto.length>0) {
        for (let i=0; i<tipiContenuto.length; i++) {
            if (tipiContenuto[i].idTipoFile==idTipoFile) {
                desc = tipiContenuto[i].descTipoFile;
                break;
            }
        }
    }
    return desc;
  }


  const columns = [

    { field: 'descFile', headerName: 'Descrizione', flex: 2, sortable: true, headerClassName: 'tableHeader' },
    { field: 'tipoContenuto', headerName: 'Tipo', flex: 1, sortable: true, headerClassName: 'tableHeader',
        renderCell: (params) => {
            let desc = getTipoFileDesc(params.row.tipoContenuto);

            return desc;
        } 
    },
    { field: 'pathFile', headerName: 'File', flex: 1, sortable: true, headerClassName: 'tableHeader'},
    { field: 'folderPath', headerName: 'Cartella', flex: 1, sortable: true, headerClassName: 'tableHeader'},
    { field: 'action', headerName:'', width: 160, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          const action = event.target.dataset.action;
          if (action=="delete") {
            handleDelete(params.row.idFile);
          }   
          else
          if (action=="edit") {
            handleEdit(params.row.idFile);
          }
          else  
          if (action=="preview") {
            handlePreview(params.row.idFile);
          }            
        };

        let showUp = params.row.ordine>1;
        let showDown = params.row.ordine==1;
  
        return  <div className="tableActionSmallButton">
                  <img data-action="preview" src="/images/preview_btn.svg" onClick={onClick} />
                  <img data-action="edit" src="/images/edit_btn.svg" onClick={onClick}  />
                  <img data-action="delete" src="/images/delete_btn.svg" onClick={onClick} />
                </div>;
      }
    }

  ];

  function findFileInRows(idFile) {
    if (rows!=null && rows.length>0) {
      for (var i=0; i<rows.length; i++) {
        if (rows[i].idFile==idFile) {
          return rows[i];
        }
      }
    }
    return null;
  }

  function handleNew() {
    const dest = "/contenuti/file?action=new&folder="+(selectedFolder!=null ? selectedFolder : 1);
    navigate(dest);
  }  

  function handleNewAvatar() {
    const dest = "/contenuti/avatar?action=new&folder="+(selectedFolder!=null ? selectedFolder : 1);
    navigate(dest);    
  }

  function handleEdit(idFile) {
    const dest = "/contenuti/file?action=edit&idfile="+idFile;
    navigate(dest);
  }

  function handlePreview(idFile) {
    let file = findFileInRows(idFile);
    if (file!=null && file.urlFile!=null) {
      window.open(file.urlFile,'_blank');
    }
  }

  function handleDelete(idFile) {
    setIdToDelete(idFile);
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);
    apiDeleteConFile( idToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        initPage();
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  };

  function onTipoContenutoFilterChange(event) {
    let value = event.target.value;
    setTipoContenutoFilter(value);
  }
  
  function onSearchStringFilterChange(event) {
    let value = event.target.value;
    setSearchStringFilter(value);
  }

  function handleSearch() {
    initPage();
  }
  
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }
  
  function folderActionCallback(action, folderId, path) {
    console.log("folderActionCallback="+action+"  folder="+folderId);
    console.log("folderActionCallback path", path);
    if (action!=null) {
      if (action=="select") {
        onFolderSelect(folderId, path);
      }
      else
      if (action=="add") {
        navigate("/contenuti/folder/-1?parent="+folderId);
      }
      else
      if (action=="edit") {
        navigate("/contenuti/folder/"+folderId);
      }     
      else
      if (action=="del") {
        handleDeleteFolder(folderId);
      }  
      else
      if (action=="undo") {
        handleUndoFolder(folderId);
      }       
    }
  }

  function onFolderSelect(idFolder, path) {
    setSelectedFolder(idFolder);
    apiGetConFileGetAllFilesByFolder(idFolder, 0, searchStringFilter, function(filesResp) {
      if (filesResp!=null && filesResp.status>=200 && filesResp.status<400) {
          let fileList = filesResp.data; 
          setRows(fileList);
      }

      
    });
  }

  function handleUndoFolder(idFolder) {
    apiGetConFolderRipristina(idFolder, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        if (selectedFolder!=null) {
          setSelectedFolder(selectedFolder.idParentFolder);
        }
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  }

  function handleDeleteFolder(idFolder) {
    setIdFolderToDelete(idFolder);
    setDeleteFolderDialogOpen(true);
  }

  const handleDeleteFolderDialogClose = () => {
    setDeleteFolderDialogOpen(false);
  };

  const handleDeleteFolderDialogConfirm = () => {
    setDeleteFolderDialogOpen(false);
    apiDeleteConFolder( idFolderToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        if (selectedFolder!=null) {
          setSelectedFolder(selectedFolder.idParentFolder);
        }
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  };  

  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      
      <div id="avp-cms-screen-container" className="clearfix" >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 

        <div className="avp-cms-screen-title">{pageTitle}</div>
        <div className="avp-cms-screen-content clearfix" >
          
          <div className="avp-cms-screen-toolbar clearfix"  >
            <div className="avp-cms-screen-toolbar-title"  >{rows.length}&nbsp;Contenuti</div>
            <div className="avp-cms-screen-toolbar-filters"  ></div>
            <div className="avp-cms-screen-toolbar-add" onClick={handleNewAvatar} style={{"marginLeft" : "20px"}} ><button>CREA AVATAR</button></div>
            <div className="avp-cms-screen-toolbar-add" onClick={handleNew} style={{"marginLeft" : "20px"}}><button>CREA NUOVO</button></div>

          </div >

          <div className="avp-cms-screen-content-row clearfix" style={{"display":"none"}}>
              <div className="avp-cms-screen-content-column"  >
                  <label htmlFor="avp-form-tipofile" >Tipo Contenuto</label><br/>
                  <select id="avp-form-tipofile" defaultValue={tipoContenutoFilter} onChange={onTipoContenutoFilterChange}>
                      <option key={-1} value=""  >Tutti</option>
                      { 
                          tipiContenuto!=null && tipiContenuto.length>0 ?
                          tipiContenuto.map((item, i) => { 
                              return (<option key={item.idTipoFile} value={item.idTipoFile}  >{item.descTipoFile}</option>) 
                          })
                          : (<option></option>)
                          
                          
                      }  
                  </select> 
              </div> 

              <div className="avp-cms-screen-content-column" >
                  <label htmlFor="avp-form-pathfile"  >Testo di ricerca</label><br/>
                  <input id="avp-form-pathfile"  type='text' value={searchStringFilter} onChange={onSearchStringFilterChange} onKeyDown={handleKeyDown} />
              </div>                

              <div className="avp-cms-screen-content-column" >
                <button onClick={handleSearch} style={{ "borderRadius": "10px", "height" : "34px", "marginTop": "20px"}}>CERCA</button>
              </div> 
          </div> 

          <div id="avp-cms-contenuti-screen-box" className="clearfix" >
              <div id="avp-cms-contenuti-screen-box-tree" style={{"position":"relative", "float":"left", "width":"30%"}} >
                <GestioneContenutiTree actionCallback={folderActionCallback}  showToolbar={true} initialFolder={selectedFolder!=null ? selectedFolder : 1} showInitialFolder={true} autoExpandToSelection={true} showBin={true}   />
              </div>
              <div id="avp-cms-contenuti-screen-box-list" style={{"position":"relative", "float":"left", "width":"70%"}}>
                <Box sx={{ width: '100%', }} >
                <DataGrid
                  disableColumnMenu
                  getRowId={(row) => row.idFile}
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    'tableRow'
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10,20,30,40]}
                  disableRowSelectionOnClick
                  autoheight
                />
                </Box>
              </div>
              
          </div>


        </div>
      </div>

      <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Conferma eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Eliminare il contenuto selezionato?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} >NO</Button>
          <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={deleteFolderDialogOpen}
          onClose={handleDeleteFolderDialogClose}
          aria-labelledby="alert-folder-dialog-title"
          aria-describedby="alert-folder-dialog-description"
        >
        <DialogTitle id="alert-folder-dialog-title">
          {"Conferma eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-folder-dialog-description">
            Eliminare la cartella selezionata e tutti i suoi contenuti?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteFolderDialogClose} >NO</Button>
          <Button onClick={handleDeleteFolderDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>      
    </div>     
  ); 

} 