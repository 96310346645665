import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import BreadCrumb from '../shared/header/BreadCrumb';
import { DataGrid } from '@mui/x-data-grid';
import { userIsLogged, apiGetSysSettings, apiGetSysSetting, apiPostSysSetting, apiDeleteSysSetting } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function GestioneSistemaScreen() {  

  const navigate = useNavigate();

  const [rows, setRows] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const breadCrumb = [
    { title: 'Gestione Sistema', link:'/sistema' }
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });


  function startPage() {
    console.log("startPage");

    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }

    
  }

  function initPage() {
    console.log("initPage");

    apiGetSysSettings(function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setRows(response.data); 
      }
      else {
        setRows([]); 
      }
    });


  }

  function stopPage() {
    console.log("stopPage");

  }



  const columns = [

    { field: 'idSetting', headerName: 'ID', flex: 1, headerClassName: 'tableHeader' },
    { field: 'chiave', headerName: 'Chiave', flex: 2, headerClassName: 'tableHeader' },
    { field: 'valore', headerName: 'Valore', flex: 2, headerClassName: 'tableHeader' },
    { field: 'settingGroup', headerName: 'Gruppo', flex: 2, headerClassName: 'tableHeader' },
    { field: 'action', headerName:'', flex: 2, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          console.log(params.row);
          const action = event.target.dataset.action;
          if (action=="edit") {
            handleEdit(params.row.idSetting)
          }
          else
          if (action=="delete") {
            handleDelete(params.row.idSetting);
          }
       

        };
  
        return <div className="tableActionButton">
            <img data-action="edit" src="/images/edit_btn.svg" onClick={onClick} />
            <img data-action="delete" data-rowid="" src="/images/delete_btn.svg" onClick={onClick} style={{ display : "none" }} />
            <img data-action="restore" data-rowid="" src="/images/restore_user.svg" onClick={onClick} style={{ display : "none"  }}  />
          </div>;
      }
    }

  ];

  function handleNew() {
    const dest = "/sistema/-1";
    navigate(dest);
  }


  function handleEdit(idSetting) {
    const dest = "/sistema/"+idSetting;
    navigate(dest);
  }

  function handleDelete(idSetting) {
    setIdToDelete(idSetting);
    setDeleteDialogOpen(true);
  }


  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);

    apiDeleteSysSetting(idToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        initPage();
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  };


  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"sistema"} />
      
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 


        <div className="avp-cms-screen-title">Crea o modifica le impostazioni di sistema</div>
        <div className="avp-cms-screen-content"  >
          
          <div className="avp-cms-screen-toolbar clearfix"  >
            <div className="avp-cms-screen-toolbar-title"  >{rows.length}&nbsp;Impostazioni</div>
            <div className="avp-cms-screen-toolbar-filters"  ></div>
            <div className="avp-cms-screen-toolbar-add" onClick={handleNew} style={{display:"none"}}><button>CREA NUOVA VOCE</button></div>
          </div >


          <DataGrid
          disableColumnMenu
          getRowId={(row) => row.idSetting}
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            'tableRow'
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 30, 50]}
          disableRowSelectionOnClick
        />
        </div>
      </div>

      <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Conferma eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vuoi eliminare la voce selezionata?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} >NO</Button>
          <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>
    </div>     
  ); 

} 