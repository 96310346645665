import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { apiGetAvpArcoMoveUpArco, apiGetAvpArcoMoveDownArco, apiDeleteAvpArco } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { DataGrid } from '@mui/x-data-grid';
import { findNodeLabel } from './AvpGrafoUtil';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AvpNodeContentArcsSection({pAvp, pNode, pArchi, pLingue, pActionCallback}) {  

  const navigate = useNavigate();

  const [rows, setRows] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idArcoToDelete, setIdArcoToDelete] = useState(null);
  const [currentNode, setCurrentNode] = useState(null)

  if (currentNode==null || (currentNode!=null && currentNode.idNodo!=pNode.idNodo)) {
    let tempNodo = Object.assign({}, pNode);
    setCurrentNode(tempNodo);
  }
  

  useEffect(() => {
    console.log("archi update:", pArchi);
    setRows(pArchi);
    
  }, [pNode, pAvp, pArchi]);


  const columns = [

    { field: 'testo', headerName: 'Testo', flex: 2, sortable: false, headerClassName: 'tableHeader' },
    { field: 'idNodoEnd', headerName: 'Nodo End', flex: 2, sortable: false, headerClassName: 'tableHeader' , 
      renderCell: (params) => {
        return findNodeLabel(params.row.idNodoEnd, pAvp.nodi);
      }
    },
    { field: 'lingua', headerName: 'Lingua', flex: 2, sortable: false, headerClassName: 'tableHeader', 
      renderCell: (params) => {
        let desc = "---";
        if (pLingue!=null && pLingue.length>0) {
          for (var i=0; i<pLingue.length; i++) {
            if (pLingue[i].idLingua==params.row.idLingua) {
              desc = pLingue[i].descrizione;
              break;
            }
          }
        }
        return desc;
      }
    },
    { field: 'action', headerName:'', width: 160, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          const action = event.target.dataset.action;
          if (action=="moveup") {
            handleMoveUp(params.row.idArco);
          }
          else
          if (action=="movedown") {
            handleMoveDown(params.row.idArco);
          }          
          else
          if (action=="delete") {
            handleDelete(params.row.idArco);
          }   
          else
          if (action=="edit") {
            handleEdit(params.row.idArco);
          }  

        };

        let showUp = params.row.ordine>1;
        let showDown = params.row.ordine==1;
  
        return  <div className="tableActionSmallButton">
                  <img data-action="edit" src="/images/edit_btn.svg" onClick={onClick}  />
                  <img data-action="delete" src="/images/delete_btn.svg" onClick={onClick} />
                  <img data-action="moveup" src="/images/move_up_arrow.svg" onClick={onClick}  />
                  <img data-action="movedown" src="/images/move_down_arrow.svg" onClick={onClick}  />
                </div>;
      }
    }

  ];

  function handleMoveUp(idArco) {
    apiGetAvpArcoMoveUpArco(idArco, function(response){
      //console.log(response);
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("arcMoveUp", currentNode.idNodo, idArco);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
          
      }
    });
  }

  function handleMoveDown(idArco) {
    apiGetAvpArcoMoveDownArco(idArco, function(response){
      //console.log(response);
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("arcMoveDown", currentNode.idNodo, idArco);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
      }
    });
  }



  function handleDelete(idArco) {
    if (currentNode!=null && currentNode.nodoStart==false) {
      setIdArcoToDelete(idArco);
      setDeleteDialogOpen(true);
    }
    else {
      alert("Impossibile eliminare l'arco dal nodo START");
    }
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);
    apiDeleteAvpArco( idArcoToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("arcDelete", currentNode.idNodo, idArcoToDelete);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
      }
    });
  };


  function handleEdit(idArco) {
    navigate("/avp/grafo/nodo/arco?action=edit&idavp="+pAvp.idAvp+"&idnodo="+currentNode.idNodo+"&idarco="+idArco);
  }

  function handleNew() {
    if (currentNode!=null && currentNode.nodoStart==false) {
      navigate("/avp/grafo/nodo/arco?action=edit&idavp="+pAvp.idAvp+"&idnodo="+currentNode.idNodo);
    }
    else {
      alert("Non è consentito aggiungere ulteriori archi");
    }
  }

  if (currentNode!=null ) {
    return (
      <div id="avp-gestioneavp-nodearcs-section"  >
          <div className="group">
            <div className="avp-gestioneavp-nodearcs-section-title" style={{"position":"relative", "float":"left"}}>
              Archi
            </div>
            <div style={{"position":"relative", "float":"right"}}>
              <button style={{"height":"35px"}} onClick={handleNew}  >NUOVO ARCO</button>
            </div>
          </div>
  
          <div className="avp-gestioneavp-nodearcs-section-box">
            <DataGrid
              sx={{ 
                m: 1,
                '& .MuiDataGrid-root': {
                  margin: 0,
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                },
                '& .MuiDataGrid-cell': {
                  fontSize: 13,
                }, 
                '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
                '& .MuiDataGrid-filler': {
                  display: 'none',
                }
              }} 
              rowHeight={40}
              disableColumnMenu
              cellSelection={false}
              getRowId={(row) => row.idArco}
              rows={rows}
              columns={columns}
              getRowClassName={(params) =>
                'tableRow'
              }
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10,20]}
              disableRowSelectionOnClick
            />
          </div>
  
          <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {"Conferma eliminazione"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Eliminare l'arco selezionato?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} >NO</Button>
            <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
          </DialogActions>
        </Dialog>
      </div> 
      
      
    );   
  }
  else {
    return (
      <div id="avp-gestioneavp-nodearcs-section"  ></div>
    );
  }



} 