import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { userIsLogged, apiGetConFolderTreeByUser, getUserAnag } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { styled } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

export default function GestioneContenutiTree({showToolbar, actionCallback, initialFolder, showInitialFolder, autoExpandToSelection, showBin}) {  

  const navigate = useNavigate();
  const [tree, setTree] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [expanded, setExpanded] = useState([]);
  const [showToolbarForBin, setShowToolbarForBin] = useState(false);
  const apiRef = useTreeViewApiRef();

  const CustomTreeItem = styled(TreeItem)({
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
  });

  useEffect(() => {
    console.log("initialFolder", initialFolder);
    initPage();
    
    

  }, [initialFolder])

  function findFolderInItems(pathItems, idFolder) {
    let found = null;
    if (pathItems!=null && pathItems.length>0) {
      for (var i=0; i<pathItems.length; i++) {
        if (pathItems[i].idFolder==idFolder) {
          found = pathItems[i];
        }
        else {
          found = findFolderInItems(pathItems[i].childFolders, idFolder);
        }
        if (found!=null) {
          break;
        }
      }
    }
    return found;
  }

  function calculatePath(pathItems, idFolder) {
    let pathArray = [];
    let last = findFolderInItems(pathItems, idFolder);
    if (last!=null) {
      calculateParentPath(pathItems, last, pathArray);
    }
    else {
      console.warn("folder not found "+idFolder);
    }
    console.log("pathArray",pathArray);
    return pathArray;
  }

  function calculateParentPath(pathItems, folder, pathArray) {
    if (folder!=null) {
      pathArray.splice(0, 0, "F_"+folder.idFolder);
      if (folder.idParentFolder!=0) {
        let parent = findFolderInItems(pathItems, folder.idParentFolder);
        if (parent!=null)
          calculateParentPath(pathItems, parent, pathArray);   
      }
    }
  }


  function initPage() {
    console.log("initPage");
    


    const idUtente = getUserAnag().idutente;
    apiGetConFolderTreeByUser(idUtente, function(respTree) {
        if (respTree!=null && respTree.status>=200 && respTree.status<400) {
            setTree(respTree.data);

            if (selectedFolder==null || (initialFolder!=null && selectedFolder!=null && selectedFolder!=initialFolder) ) {
              if (autoExpandToSelection) {
                let parentInitialFolder = 1;
                if (initialFolder!=null) {
                  if (showInitialFolder) {
                    parentInitialFolder = initialFolder;
                  }
                  else {
                    let folder = findFolderInItems(respTree.data, initialFolder);
                    if (folder!=null && folder.idParentFolder!=0) {
                      parentInitialFolder = folder.idParentFolder;
                    }
                  }
                }
                console.log("parentInitialFolder", parentInitialFolder);
                setSelectedFolder(parentInitialFolder);
                let myExpandedFolder = calculatePath(respTree.data, initialFolder);
                setExpanded(myExpandedFolder);
              }
              else {
                setSelectedFolder(null);
                setExpanded(null);
              }
            }
            
        }        
    });
  }


  function drawTree() {
    if (tree!=null && tree.length>0) {
        if (autoExpandToSelection) {
          console.log("selectedFolder", selectedFolder);
          console.log("expanded", expanded);
          return (
            <SimpleTreeView
              selectedItems={["F_"+selectedFolder]}
              expandedItems={expanded}
              onSelectedItemsChange={handleItemSelectionToggle}
              slots={{
              expandIcon: AddBoxIcon,
              collapseIcon: IndeterminateCheckBoxIcon,
            }}
            >
              {
                  tree.map((item, i) => { 
                      return drawFolder(item);
                  })
              }
            </SimpleTreeView>
          );             
        }
        else {
          return (
            <SimpleTreeView
              onSelectedItemsChange={handleItemSelectionToggle}
              slots={{
              expandIcon: AddBoxIcon,
              collapseIcon: IndeterminateCheckBoxIcon,
            }}
            >
              {
                  tree.map((item, i) => { 
                      return drawFolder(item);
                  })
              }
            </SimpleTreeView>
          );
        }


    }
  }

  function drawFolder(folder) {
    if (folder!=null) {
        if (folder.idFolder==2 && showBin==false) return;

        let folderId = "F_"+folder.idFolder;
        let folderLabel = folder.descFolder;
        if (folder.childFolders!=null && folder.childFolders.length>0) {

            return (
                <CustomTreeItem key={folder.idFolder} itemId={folderId} data-idfolder={folder.idFolder} label={folderLabel}  >
                {
                                folder.childFolders!=null && folder.childFolders.length>0 ?
                                folder.childFolders.map((item, i) => { 
                                    return drawFolder(item);
                                })
                                :
                                (<div></div>)
                }
                </CustomTreeItem>
            )
        }
        else {
            if (folder.idFolder!=initialFolder) {
              return (<CustomTreeItem key={folder.idFolder}  itemId={folderId} data-idfolder={folder.idFolder} label={folderLabel}  />);
            }
            else {
              if (showInitialFolder) {
                return (<CustomTreeItem key={folder.idFolder}  itemId={folderId} data-idfolder={folder.idFolder} label={folderLabel}  />);
              }
              else {
                return;
              }
              
            }
              
        }
    }
    else 
        return ;
  }



  function EndIcon() {
    return <div style={{ width: 24 }} />;
  }

  function onActionFolderClick(event) {
    event.stopPropagation(); // don't select this row after clicking

    const action = event.target.dataset.action;
    if (action=="add") {
        actionCallback("add", selectedFolder);
    }   
    else
    if (action=="edit") {
        actionCallback("edit", selectedFolder);
    }   
    else
    if (action=="del") {
        actionCallback("del", selectedFolder);
    }       
    else
    if (action=="undo") {
        actionCallback("undo", selectedFolder);
    }        
  }

  const handleItemSelectionToggle = (event, itemId) => {
    console.log(itemId);
    //if (isSelected) {
    
        let idFolder = itemId.replace("F_", "");
        let path = calculatePath(tree, idFolder);

        if (path!=null && path.length>0 && path.indexOf("F_2")!=-1) {
          setShowToolbarForBin(true);
        }
        else {
          setShowToolbarForBin(false);
        }

        let myExpandedFolder = calculatePath(tree, idFolder);
        setExpanded(myExpandedFolder);
        setSelectedFolder(idFolder);
        actionCallback("select", idFolder, path);
        
    //}
  };

  return (
    <div id="avp-gestionecontenuti-tree"  >
        <div id="avp-gestionecontenuti-tree-toolbar" className="tableActionSmallButton" style={{"marginBottom":"10px", "display": (showToolbar ? "block" : "none")}}>
            <img data-action="add" src="/images/folder_add.svg" onClick={onActionFolderClick}  style={{"display": (showToolbarForBin==false ? "inline" : "none")}} />
            <img data-action="edit" src="/images/folder_edit.svg" onClick={onActionFolderClick} style={{"display": (showToolbarForBin==false ? "inline" : "none")}} />
            <img data-action="del" src="/images/folder_delete.svg" onClick={onActionFolderClick} style={{"display": (showToolbarForBin==false ? "inline" : "none")}} />
            {
              showToolbarForBin ?
                selectedFolder!=2 ?
                (
                  <img data-action="undo" src="/images/folder_redo.svg" onClick={onActionFolderClick}  />
                )
                :
                (
                  <img data-action="undo" src="/images/folder_redo_off.svg" style={{"cursor":"default"}} />
                )
              :
              (
                <span></span>
              )  
            }
            
        </div>
        { drawTree() }
       
    </div>     
  ); 

} 