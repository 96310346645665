import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import BreadCrumb from '../shared/header/BreadCrumb';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import { userIsLogged, apiGetAvpIstanzaById, apiGetAnaUtenteIndex, apiGetAvpTipoIstanze, apiPostAvpIstanza, apiGetAvpQRCodeAlign, apiGetAvpQRCodeStili, apiGetAvpQRCodePresets } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

export default function GestioneAvpIstanzeDetailScreen() {  

  const navigate = useNavigate();

  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const [currentAvpIstanza, setCurrenteAvpIstanza] = useState(null)
  const [currentAvp, setCurrentAvp] = useState(null)
  const [currentUtenti, setCurrentUtenti] = useState([])
  const [currentTipiIstanze, setCurrentTipiIstanze] = useState([])
  const [currentQRCodePresets, setCurrentQRCodePresets] = useState([])
  const [currentQRCodeStili, setCurrentQRCodeStili] = useState([])
  const [currentQRCodeAlign, setCurrentQRCodeAlign] = useState([])

  //FORM
  const [formUtenteStatus, setFormUtenteStatus] = useState('')
  const [formTipoIstanzaStatus, setFormTipoIstanzaStatus] = useState('')
  const [formDurataSessioneStatus, setFormDurataSessioneStatus] = useState('')
  const formUtenteSelect = React.useRef();
  const formTipoIstanzaSelect = React.useRef();
  const formDurataSessioneInput = React.useRef();
  const formPinInput = React.useRef();
  const formQRCodeCheckbox = React.useRef();


  const breadCrumb = [
    { title: 'Gestione AVP', link:'/avp' },
    { title: 'AVP '+(currentAvp!=null ? currentAvp : ""), link:null },
    { title: 'Istanze', link:"/avp/istanze?idavp="+(currentAvp!=null ? currentAvp : "") },
    { title: 'Modifica Istanza', link:null },
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });


  function startPage() {
    console.log("startPage");

    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }

  function stopPage() {
    console.log("stopPage");
  }



  function initPage() {
    console.log("initPage");

    const url = window.location.pathname;
    const idAvp = getValueByQueryString("idavp");
    setCurrentAvp(idAvp);
    const idAvpIstanza = url.substring(url.lastIndexOf('/') + 1);
    
    if (idAvpIstanza!=null && idAvpIstanza!="") {
      apiGetAvpIstanzaById(idAvpIstanza, avpIstanzaCallback);
    }
   
  }

  function avpIstanzaCallback(response) {
    console.log(response);
    if (response!=null && response.status>=200 && response.status<400) {
      console.log(response.data);
      setCurrenteAvpIstanza(response.data);
      apiGetAnaUtenteIndex("0", utentiCallback);
    }
  }

  function utentiCallback(response) {
    console.log(response);
    if (response!=null && response.status>=200 && response.status<400) {
      console.log(response.data);
      setCurrentUtenti(response.data);
      apiGetAvpTipoIstanze(tipiIstanzeCallback);
    }
  }

  function tipiIstanzeCallback(response) {
    console.log(response);
    if (response!=null && response.status>=200 && response.status<400) {
      console.log(response.data);
      setCurrentTipiIstanze(response.data);
      loadQRCodePresets(function(){
        loadQRCodeStili(function(){
          loadQRCodeAlign(function(){

                   
          })
        });
      });
    }
  }

  function loadQRCodePresets(callback) {
    apiGetAvpQRCodePresets( function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setCurrentQRCodePresets(response.data);
      } 
      callback(); 
    });
  }

  function loadQRCodeStili(callback) {
    apiGetAvpQRCodeStili( function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setCurrentQRCodeStili(response.data);
      } 
      callback(); 
    });
  }  

  function loadQRCodeAlign(callback) {
    apiGetAvpQRCodeAlign( function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setCurrentQRCodeAlign(response.data);
      } 
      callback(); 
    });
  }  

  function onUtenteChange(event) {
    let value = event.target.value;
    currentAvpIstanza.idUtente = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);  
  }  

  function onTipoIstanzaChange(event) {
    let value = event.target.value;
    currentAvpIstanza.idTipoIstanza = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);      
  }  

  function onDurataSessioneChange(event) {
    let value = event.target.value;
    currentAvpIstanza.durataSessione = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj); 
  }

  function onPinChange(event) {
    let value = event.target.value;
    currentAvpIstanza.pin = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj); 
  }

  function onQRCodeChange(event) {
    let value = event.target.checked;
    currentAvpIstanza.qrCode = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj); 

  }  

  function onQRCodeTextChange(event) {
    let value = event.target.value;
    currentAvpIstanza.qrCodeText = value;
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj); 

  }  

  function onQRCodePresetChange(event) {
    let value = event.target.value;
    currentAvpIstanza.qrCodePreset = eval(value);
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);      
  }  

  function onQRCodeStileChange(event) {
    let value = event.target.value;
    currentAvpIstanza.qrCodeStyleClass = eval(value);
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);      
  }    

  function onQRCodeAlignChange(event) {
    let value = event.target.value;
    currentAvpIstanza.qrCodeAllineamento = eval(value);
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);      
  } 

  function onQRCodeSizeChange(event) {
    let value = event.target.value;
    currentAvpIstanza.qrCodeDimensioniQR = eval(value);
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);      
  }   

  function validateForm() {
    console.log("validateForm");
    let valid = true;


    return valid;    
} 

function saveIstanza() {


  if (currentAvpIstanza!=null) {
    if (currentAvpIstanza.qrCodeStyleClass==null && currentQRCodeStili!=null && currentQRCodeStili.length>0) {
      currentAvpIstanza.qrCodeStyleClass = currentQRCodeStili[0].idQRCodeStile;
    }
    if (currentAvpIstanza.qrCodeDimensioniQR==null) {
      currentAvpIstanza.qrCodeDimensioniQR = 400;
    }      
    let newObj = Object.assign({}, currentAvpIstanza);
    setCurrenteAvpIstanza(newObj);
  }

  apiPostAvpIstanza(currentAvpIstanza, function(response) {
    if (response!=null && response.status>=200 && response.status<400) {
      setAlertSuccessOpen(true);
      setAlertErrorOpen(false);
    }
    else {
      setAlertSuccessOpen(false);
      setAlertErrorOpen(true);
    }
  });
}


  function footerCancelCallback() {
    console.log("cancel");

    navigate("/avp/istanze?idavp="+currentAvp);
  }

  function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
      saveIstanza();
    }
  }  
  


  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"avp"} />
      <BreadCrumb items={breadCrumb} />
      <div id="avp-cms-screen-container"  >
      <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse>         
        <div className="avp-cms-screen-title">Dati Istanza AVP</div>
        <div className="avp-cms-screen-content"  >

          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-utente" className='required'>Utente</label><br/>
              <select ref={formUtenteSelect} id="avp-form-utente" value={currentAvpIstanza!=null ? currentAvpIstanza.idUtente : ""} onChange={onUtenteChange}>
                { 
                  currentUtenti!=null && currentUtenti.length>0 ?
                  currentUtenti.map((item, i) => { 
                      if (currentAvpIstanza!=null ) {
                        return (<option key={item.idutente} value={item.idutente}  >{item.userName}</option>) 
                      }
                      else {
                        return "";
                      }
                      
                    })
                  : (<option></option>)
                    
                  
                }  
              </select>            
            </div>              
          </div>

          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label htmlFor="avp-form-tipoistanza" className='required'>Tipo Istanza</label><br/>
              <select ref={formTipoIstanzaSelect} id="avp-form-tipoistanza" value={currentAvpIstanza!=null ? currentAvpIstanza.idTipoIstanza : ""} onChange={onTipoIstanzaChange} >
                { 
                  currentTipiIstanze!=null && currentTipiIstanze.length>0 ?
                  currentTipiIstanze.map((item, i) => { 
                      if (currentAvpIstanza!=null ) {
                        return (<option key={item.idTipoIstanza} value={item.idTipoIstanza}  >{item.descTipoIstanza}</option>) 
                      }
                      else {
                        return "";
                      }
                      
                    })
                  : (<option></option>)
                    
                  
                }  
              </select>            
            </div>              
          </div>


          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label for="avp-form-duratasessione" className='required'>Durata sessione</label><br/>
              <input id="avp-form-duratasessione" ref={formDurataSessioneInput} className={formDurataSessioneStatus} value={currentAvpIstanza!=null ? currentAvpIstanza.durataSessione : 0}  defaultValue="0" type='number' min="0" onChange={onDurataSessioneChange} />
            </div>  
          </div>

          <div className="avp-cms-screen-content-row clearfix">
            <div className="avp-cms-screen-content-column">
              <label for="avp-form-pin" >PIN</label><br/>
              <input id="avp-form-pin" ref={formPinInput} type='text' value={currentAvpIstanza!=null ? currentAvpIstanza.pin : ""}  defaultValue="" onChange={onPinChange} />              
            </div>              
          </div>

          <div className="avp-cms-screen-content-row clearfix">
            <input id="avp-form-qrcode" ref={formQRCodeCheckbox} type='checkbox' onChange={onQRCodeChange}  checked={currentAvpIstanza!=null && currentAvpIstanza.qrCode ? "checked" : ""} />            
            <label for="avp-form-qrcode">QR Code</label>
          </div>

          <div id="avp-form-qrcode-settings" style={{"display": (currentAvpIstanza!=null && currentAvpIstanza.qrCode ? "block" : "none")  }}>
            
            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column" style={{"width":"50%"}}>
                <label for="avp-form-qrcode-text" >Testo</label><br/>
                <input id="avp-form-qrcode-text" type='text' value={currentAvpIstanza!=null ? currentAvpIstanza.qrCodeText : ""}  style={{"max-width":"none"}} defaultValue="" onChange={onQRCodeTextChange} />              
              </div>              
            </div>

            <div className="avp-cms-screen-content-row clearfix">
              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-qrcode-preset" >Preset</label><br/>
                <select id="avp-form-qrcode-preset" value={currentAvpIstanza!=null ? currentAvpIstanza.qrCodePreset : ""} onChange={onQRCodePresetChange} >
                  { 
                    currentQRCodePresets!=null && currentQRCodePresets.length>0 ?
                    currentQRCodePresets.map((item, i) => { 
                        if (currentAvpIstanza!=null ) {
                          return (<option key={item.idQRCodePreset} value={item.idQRCodePreset}  >{item.descQRCodePreset}</option>) 
                        }
                        else {
                          return "";
                        }
                      })
                    : (<option></option>)
                  }  
                </select>            
              </div> 

              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-qrcode-stile" >Stili</label><br/>
                <select id="avp-form-qrcode-stile" value={currentAvpIstanza!=null ? currentAvpIstanza.qrCodeStyleClass : ""} onChange={onQRCodeStileChange} >
                  { 
                    currentQRCodeStili!=null && currentQRCodeStili.length>0 ?
                    currentQRCodeStili.map((item, i) => { 
                        if (currentAvpIstanza!=null ) {
                          return (<option key={item.idQRCodeStile} value={item.idQRCodeStile}  >{item.nomeStile}</option>) 
                        }
                        else {
                          return "";
                        }
                      })
                    : (<option></option>)
                  }  
                </select>            
              </div> 

              <div className="avp-cms-screen-content-column">
                <label htmlFor="avp-form-qrcode-align" >Allineamento</label><br/>
                <select id="avp-form-qrcode-align" value={currentAvpIstanza!=null ? currentAvpIstanza.qrCodeAllineamento : ""} onChange={onQRCodeAlignChange} >
                  { 
                    currentQRCodeAlign!=null && currentQRCodeAlign.length>0 ?
                    currentQRCodeAlign.map((item, i) => { 
                        if (currentAvpIstanza!=null ) {
                          return (<option key={item.idQRCodeAlign} value={item.idQRCodeAlign}  >{item.descQRCodeAlign}</option>) 
                        }
                        else {
                          return "";
                        }
                      })
                    : (<option></option>)
                  }  
                </select>            
              </div> 

              <div className="avp-cms-screen-content-column" >
                <label for="avp-form-qrcode-size" >Dimensioni</label><br/>
                <input id="avp-form-qrcode-size" type='number' value={currentAvpIstanza!=null ? currentAvpIstanza.qrCodeDimensioniQR : "400"}  style={{"max-width":"none"}} defaultValue="400" onChange={onQRCodeSizeChange} />              
              </div> 

            </div>



          </div>

        
          
        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}   />
    </div>     
  ); 

} 