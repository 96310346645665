import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import AvpSaveFooter from '../shared/footer/AvpSaveFooter';
import BreadCrumb from '../shared/header/BreadCrumb';
import { userIsLogged, getUserAnag, apiGetAnaGruppoUtente, apiPostAnaGruppoUtente } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import { getValueByQueryString } from '../shared/manager/util';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


export default function GestioneGruppoUtenteDetailScreen() {  

  const navigate = useNavigate();
  
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  let breadCrumb = [
    { title: 'Gestione Utenti', link:'/utenti' },
    { title: 'Gestione Gruppi', link:'/utenti/gruppi' },
    { title: 'Crea/Modifica Gruppo', link:null }
  ];

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(breadCrumb);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [pageAction, setPageAction] = useState("new");
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
 
  //FORM
  const [formNomeStatus, setFormNomeStatus] = useState('');
  const [formFattStatus, setFormFattStatus] = useState('');
  const [formEmailStatus, setFormEmailStatus] = useState('');
  const [formTelefonoStatus, setFormTelefonoStatus] = useState('');
  const [formRifAmmStatus, setFormRifAmmStatus] = useState('');


  const formNomeInput = React.useRef();
  const formFattInput = React.useRef();
  const formEmailInput = React.useRef();
  const formTelefonoInput = React.useRef();
  const formRifAmmInput = React.useRef();



  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });


  function startPage() {
    console.log("startPage");
    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }
  }
  
  function stopPage() {
    console.log("stopPage");

  }

  function initPage() {
    console.log("initPage");

    loadGruppo();
    
  }

  function loadGruppo() {
    const  url = window.location.pathname;
    const idGruppoUtente = url.substring(url.lastIndexOf('/') + 1);

    if (idGruppoUtente!==null && idGruppoUtente!=="" && idGruppoUtente!="-1") {
        //edit
        setPageAction("edit");
        
        apiGetAnaGruppoUtente(idGruppoUtente, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setSelectedGroup(response.data);
            }
        });
    }
    else {
        //new
        setPageAction("new");
        let newGroup = {
            descrizioneGruppo: "",
            idFatturazione: "",
            rifAmmNomeCognome: "",
            rifAmmEmail: "",
            rifAmmTelefono: "",
            sysUser: "",
            sysDate: new Date().toISOString(),
            sysAttivo: true
        };
        setSelectedGroup(newGroup); 
    }
  }


  function handleNomeField(event) {
    let value = event.target.value;
    selectedGroup.descrizioneGruppo = value;
    let newObj = Object.assign({}, selectedGroup);
    setSelectedGroup(newObj); 
  }

  function handleFattField(event) {
    let value = event.target.value;
    selectedGroup.idFatturazione = value;
    let newObj = Object.assign({}, selectedGroup);
    setSelectedGroup(newObj); 
  }

  function handleEmailField(event) {
    let value = event.target.value;
    selectedGroup.rifAmmEmail = value;
    let newObj = Object.assign({}, selectedGroup);
    setSelectedGroup(newObj); 
  }

  function handleTelefonoField(event) {
    let value = event.target.value;
    selectedGroup.rifAmmTelefono = value;
    let newObj = Object.assign({}, selectedGroup);
    setSelectedGroup(newObj); 
  }

  function handleRifAmmField(event) {
    let value = event.target.value;
    selectedGroup.rifAmmNomeCognome = value;
    let newObj = Object.assign({}, selectedGroup);
    setSelectedGroup(newObj); 
  }  



  function validateForm() {
    console.log("validateForm");
    let valid = true;

    const nome = formNomeInput.current.value;
    const fatt = formFattInput.current.value;
    const email = formEmailInput.current.value;
    const telefono = formTelefonoInput.current.value;
    const rifAmm = formRifAmmInput.current.value;

    
    if (nome==null || nome=="") {
        valid = false;
        setFormNomeStatus(" notvalid");
    }
    else {
        setFormNomeStatus("");
    }

    if (fatt==null || fatt=="") {
        valid = false;
        setFormFattStatus(" notvalid");
    }
    else {
        setFormFattStatus("");
    }

    if (email==null || email=="") {
        valid = false;
        setFormEmailStatus(" notvalid");
    }
    else {
        setFormEmailStatus("");
    }   

    if (telefono==null || telefono=="") {
        valid = false;
        setFormTelefonoStatus(" notvalid");
    }
    else {
        setFormTelefonoStatus("");
    }     
    
    if (rifAmm==null || rifAmm=="") {
        valid = false;
        setFormRifAmmStatus(" notvalid");
    }
    else {
        setFormRifAmmStatus("");
    }  
       

    return valid;    
} 

function saveGroup() {
    if (selectedGroup!=null) {
        
        apiPostAnaGruppoUtente(selectedGroup, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
                setAlertSuccessOpen(true);
                setAlertErrorOpen(false);
                navigate("/utenti/gruppi");
            } 
            else {
                setAlertSuccessOpen(false);
                setAlertErrorOpen(true);
            } 
        });
    }
    else {
        console.log("Selected user is null");
    }

}


function footerCancelCallback() {
    console.log("cancel");
    navigate("/utenti/gruppi");
}

function footerSaveCallback() {
    console.log("save");

    if (validateForm()) {
        saveGroup();
    }
}  




  return (
    <div id="avp-gestionecontenuti-screen"  >
      <AvpHeader selectedMenuId={"contenuti"} />
      <BreadCrumb items={currentBreadCrumb} />
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen}>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 
     
        <div className="avp-cms-screen-content"  >
            
            <div className="avp-cms-screen-content-row clearfix">
                <div className="avp-gestioneavp-nodearcs-section-title" >
                Gestione Gruppo Utente
                </div>
            </div>

            <div className="avp-cms-screen-content-row clearfix">
                <div className='avp-cms-screen-content-col50' >

                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-nome" className='required' >Descrizione</label><br/>
                            <input id="avp-form-nome" ref={formNomeInput} className={formNomeStatus} style={{ "maxWidth":"none"}} type='text' value={selectedGroup!=null ? selectedGroup.descrizioneGruppo : ''} onChange={handleNomeField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-cognome" className='required' >ID Fatturazione</label><br/>
                            <input id="avp-form-cognome" ref={formFattInput} className={formFattStatus} style={{ "maxWidth":"none"}} type='text' value={selectedGroup!=null ? selectedGroup.idFatturazione : ''} onChange={handleFattField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-rifamm" className='required' >Rif. Amministrativo</label><br/>
                            <input id="avp-form-rifamm" ref={formRifAmmInput} className={formRifAmmStatus} style={{ "maxWidth":"none"}} type='text' value={selectedGroup!=null ? selectedGroup.rifAmmNomeCognome : ''} onChange={handleRifAmmField}  />
                        </div>  
                    </div>                    
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-email" className='required' >Rif. Ammin. Email</label><br/>
                            <input id="avp-form-email" ref={formEmailInput} className={formEmailStatus} style={{ "maxWidth":"none"}} type='text' value={selectedGroup!=null ? selectedGroup.rifAmmEmail : ''} onChange={handleEmailField}  />
                        </div>  
                    </div>
                    <div className="avp-cms-screen-content-row clearfix">
                        <div className="avp-cms-screen-content-column" style={{ "width":"50%"}} >
                            <label htmlFor="avp-form-telefono" className='required' >Rif. Ammin. Telefono</label><br/>
                            <input id="avp-form-telefono" ref={formTelefonoInput} className={formTelefonoStatus} style={{ "maxWidth":"none"}} type='text' value={selectedGroup!=null ? selectedGroup.rifAmmTelefono : ''} onChange={handleTelefonoField}  />
                        </div>  
                    </div>

                </div>
                <div className='avp-cms-screen-content-col50' >

                 

                </div>


            </div>

        </div>
      </div>
      <AvpSaveFooter saveCallback={footerSaveCallback} cancelCallback={footerCancelCallback}    />
    </div>     
  ); 

} 