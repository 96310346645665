import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AvpHeader from '../shared/header/AvpHeader';
import BreadCrumb from '../shared/header/BreadCrumb';
import { DataGrid } from '@mui/x-data-grid';
import { userIsLogged, apiGetAnaUtenteIndex, apiDeleteAnaUtente, apiPostAnaUtente } from "../shared/manager/api_manager";
import { useEffectOnce } from '../shared/manager/use_effect_once';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function GestioneUtentiScreen() {  

  const navigate = useNavigate();

  const [utentiStatus, setUtentiStatus] = useState(0)
  const [rows, setRows] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const breadCrumb = [
    { title: 'Gestione Utenti', link:'/utenti' }
  ];

  useEffectOnce(() => {
    startPage();
    
    return () => stopPage();
  });

  useEffect(() => {
    initPage();
  
  }, [utentiStatus])

  function startPage() {
    console.log("startPage");

    if (userIsLogged()==false) {
      navigate("/login");
    }else{
      initPage();
    }

    
  }

  function initPage() {
    console.log("initPage");

    apiGetAnaUtenteIndex(utentiStatus, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setRows(response.data); 
      }
      else {
        setRows([]); 
      }
    });


  }

  function stopPage() {
    console.log("stopPage");

  }



  const columns = [
    { field: 'status', headerName: '', width: 20, headerClassName: 'tableHeader', 
      renderCell: (params) => {
        return params.row.sysAttivo ? <div className='tableStatusActive'></div> : <div className='tableStatusInactive'></div>;
      } 
    },
    { field: 'idutente', headerName: 'ID', flex: 1, headerClassName: 'tableHeader' },
    { field: 'nome', headerName: 'Nome', flex: 2, headerClassName: 'tableHeader' },
    { field: 'cognome', headerName: 'Cognome', flex: 2, headerClassName: 'tableHeader' },
    { field: 'emailUtente', headerName: 'Email', flex: 2, headerClassName: 'tableHeader' },
    { field: 'telefono', headerName: 'Telefono', flex: 2, headerClassName: 'tableHeader' },
    { field: 'userName', headerName: 'Username', flex: 2, headerClassName: 'tableHeader' },
    { field: 'action', headerName:'', flex: 2, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          console.log(params.row);
          const action = event.target.dataset.action;
          if (action=="edit") {
            handleEdit(params.row.idutente)
          }
          else
          if (action=="delete") {
            handleDelete(params.row.idutente);
          }
          else
          if (action=="restore") {
            handleRestore(params.row.idutente);
          }          

        };
  
        return <div className="tableActionButton">
            <img data-action="edit" src="/images/edit_btn.svg" onClick={onClick} />
            <img data-action="delete" data-rowid="" src="/images/delete_btn.svg" onClick={onClick} style={{ display : (params.row.sysAttivo ? "inline" : "none") }} />
            <img data-action="restore" data-rowid="" src="/images/restore_user.svg" onClick={onClick} style={{ display : (params.row.sysAttivo ? "none" : "inline") }}  />
          </div>;
      }
    }

  ];

  function handleNew() {
    const dest = "/utenti/-1";
    navigate(dest);
  }


  function handleEdit(idUtente) {
    const dest = "/utenti/"+idUtente;
    navigate(dest);
  }

  function handleDelete(idUtente) {
    setIdToDelete(idUtente);
    setDeleteDialogOpen(true);
  }

  function handleRestore(idUtente) {
    let user = null;
    if (rows!=null && rows.length>0) {
      for (var i=0; i<rows.length; i++) {
        if (rows[i].idutente==idUtente) {
          user = rows[i];
          break;
        }
      }
    }

    if (user!=null) {
      user.sysAttivo = true;
      apiPostAnaUtente(user, function(response) {
        if (response!=null && response.status>=200 && response.status<400) {
          setAlertSuccessOpen(true);
          setAlertErrorOpen(false);
          initPage();
        }
        else {
          setAlertSuccessOpen(false);
          setAlertErrorOpen(true);
        }
      });
    }
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);

    apiDeleteAnaUtente(idToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        setAlertSuccessOpen(true);
        setAlertErrorOpen(false);
        initPage();
      }
      else {
        setAlertSuccessOpen(false);
        setAlertErrorOpen(true);
      }
    });
  };

  function onUtentiStatusChange(event) {
    let value = event.target.value;
    setUtentiStatus(value);
  }

  function handleGruppiTab() {
    const dest = "/utenti/gruppi";
    navigate(dest);
  }

  return (
    <div id="avp-gestioneavp-screen"  >
      <AvpHeader selectedMenuId={"utenti"} />
      
      <div id="avp-cms-screen-container"  >
        <Collapse in={alertSuccessOpen} timeout={{ enter: 1000, exit: 1000 }}  addEndListener={() => { setTimeout(() => {setAlertSuccessOpen(false)}, 2000); }  }>
          <Alert variant="filled" severity="success" onClose={ () => {setAlertSuccessOpen(false)} }>
            Modifica effettuata con successo.
          </Alert>
        </Collapse>  
        <Collapse in={alertErrorOpen}>
          <Alert variant="filled" severity="error" onClose={ () => {setAlertErrorOpen(false)} }>
            Si è verificato un errore durante la modifica...
          </Alert>
        </Collapse> 

        <div className="avp-cms-tab clearfix" >
            <div className={'avp-cms-tab-item avp-cms-tab-item-selected'} >
              Utenti
            </div>   
            <div className={'avp-cms-tab-item'} onClick={handleGruppiTab}>
              Gruppi
            </div>              
        </div>

        <div className="avp-cms-screen-title">Crea o modifica un utente</div>
        <div className="avp-cms-screen-content"  >
          
          <div className="avp-cms-screen-toolbar clearfix"  >
            <div className="avp-cms-screen-toolbar-title"  >{rows.length}&nbsp;Utenti</div>
            <div className="avp-cms-screen-toolbar-filters"  ></div>
            <div className="avp-cms-screen-toolbar-add" onClick={handleNew} ><button>CREA NUOVO UTENTE</button></div>
          </div >

          <div className="avp-cms-screen-content-row clearfix">

              <div className="avp-cms-screen-content-column"  >
                  <label htmlFor="avp-form-tipofile" >Stato</label><br/>
                  <select id="avp-form-tipofile" value={utentiStatus} onChange={onUtentiStatusChange}>
                      <option key={0} value="0"  >Attivi</option>
                      <option key={1} value="1"  >Non attivi</option>
                  </select> 
              </div> 
          </div> 

          <DataGrid
          disableColumnMenu
          getRowId={(row) => row.idutente}
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            'tableRow'
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 30, 50]}
          disableRowSelectionOnClick
        />
        </div>
      </div>

      <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Conferma disabilitazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vuoi disabilitare l'utente selezionato?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} >NO</Button>
          <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>
    </div>     
  ); 

} 