export function findNode(idNodo, nodi) {
    let result = null;
    if (nodi!=null && nodi.length>0) {
      for (var i=0; i<nodi.length; i++) {
        if (nodi[i].idNodo==idNodo ) {
          result = nodi[i];
          break;
        } 
      }
    } 
    return result;
}

export function findStartNode(nodi) {
  let result = null;
  if (nodi!=null && nodi.length>0) {
    for (var i=0; i<nodi.length; i++) {
      if (nodi[i].nodoStart ) {
        result = nodi[i];
        break;
      } 
    }
  } 
  return result;
}

export function findNodeLabel(idNodo, nodi) {
    let nodo = findNode(idNodo, nodi);
    if (nodo!=null) {
      return nodo.descNodo;
    }
    else {
      return idNodo;
    }
}