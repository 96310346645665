import React from 'react'
import ReactPlayer from 'react-player'

export default function PlaylistItem({item, loop, size, finishCallback}) {  

    function handleTempoScaduto() {
        console.log("handleTempoScaduto");
        if (finishCallback!=null) {
            finishCallback(item);
        }
    }

    if (item!=null) {
        console.log("item:", item);
        if (item.tipoContenuto==2) {
            return (
                <div className="avp-playlist-item" id={item.codiceFile}  >
                    <img 
                        src={item.urlFile} 
                        width={size.width}
                        height={size.height}
                        style={{"objectFit":"contain"}}
                    />
                </div>
            );
        }
        else
        if (item.tipoContenuto==1 || item.tipoContenuto==5) {
            return (
                <div className="avp-playlist-item" id={item.codiceFile}  >
                   <ReactPlayer
                      controls={true}
                      url={item.urlFile}
                      playing={true}
                      width={size.width}
                      height={size.height}
                      loop={loop}
                      onEnded={()=>handleTempoScaduto()}
                    />                   
                </div>
            );
        }
    }

    return (
        <div className="avp-playlist-item"  ></div>
    );
  
  }   
