import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { apiGetAvpNodoPlaylist, apiGetAvpNodoPlaylistFiles, apiGetAvpPlaylistRemovePlaylistContent, apiGetAvpPlaylistMoveUpPlaylistContent, apiGetAvpPlaylistMoveDownPlaylistContent } from "../shared/manager/api_manager";
import { DataGrid } from '@mui/x-data-grid';
import { findNodeLabel } from './AvpGrafoUtil';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AvpNodeContentPlaylistSection({pAvp, pNode, pLingua, pActionCallback}) {  

  const navigate = useNavigate();

  const [rows, setRows] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idLingua, setIdLingua] = useState(null)
  const [playlist, setPlaylist] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null);


  useEffect(() => {
    initPage();
  }, [pNode, pAvp, pLingua]);

  function initPage() {
    const lingua = pLingua!=null ? pLingua.idLingua : null;
    if (lingua!=="undefined" && lingua!=null) {
      console.log("PL lingua", lingua);
      setSelectedNode(pNode);
      apiGetAvpNodoPlaylist( pNode.idNodo, lingua, function(pResp) {
        if (pResp!=null && pResp.status>=200 && pResp.status<400) {
          console.log("playlist:", pResp.data);
          setPlaylist(pResp.data);
          apiGetAvpNodoPlaylistFiles( pNode.idNodo, lingua, function(response) {
            if (response!=null && response.status>=200 && response.status<400) {
              console.log("playlistFiles:", response.data);
              setIdLingua(lingua);
              setRows(response.data);
            }
          });

        }
        

      });
    }
    else {
      console.warn("lingua is not defined");
      setRows(null);
      setIdLingua(lingua);
    }

  }

  const columns = [

    { field: 'descTipoContenuto', headerName: 'Tipo', flex: 1, sortable: false, headerClassName: 'tableHeader' },
    { field: 'nomeFile', headerName: 'Nome', flex: 2, sortable: false, headerClassName: 'tableHeader' },
    { field: 'durataVisualizzazione', headerName: 'Durata', flex: 1, sortable: false, headerClassName: 'tableHeader'},

    { field: 'action', headerName:'', width: 160, sortable: false, headerClassName: 'tableHeader', align:'right',
      renderCell: (params) => {
        
        const onClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking

          const action = event.target.dataset.action;
          if (action=="moveup") {
            handleMoveUp(params.row.idPlaylistFile);
          }
          else
          if (action=="movedown") {
            handleMoveDown(params.row.idPlaylistFile);
          }          
          else
          if (action=="delete") {
            handleDelete(params.row.idPlaylistFile);
          }   
          else
          if (action=="edit") {
            handleEdit(params.row.idPlaylistFile);
          }  

        };

        let showUp = params.row.ordine>1;
        let showDown = params.row.ordine==1;
  
        return  <div className="tableActionSmallButton">
                  <img data-action="edit" src="/images/edit_btn.svg" onClick={onClick}  />
                  <img data-action="delete" src="/images/delete_btn.svg" onClick={onClick} />
                  <img data-action="moveup" src="/images/move_up_arrow.svg" onClick={onClick}  />
                  <img data-action="movedown" src="/images/move_down_arrow.svg" onClick={onClick}  />
                </div>;
      }
    }

  ];

  function handleMoveUp(idPlaylistFile) {
  
    apiGetAvpPlaylistMoveUpPlaylistContent(idPlaylistFile, function(response){
      console.log(response);
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("playlistMoveUp", pNode.idNodo, idPlaylistFile);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
          
      }
    });
  
  }

  function handleMoveDown(idPlaylistFile) {

    apiGetAvpPlaylistMoveDownPlaylistContent(idPlaylistFile, function(response){
      console.log(response);
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("playlistMoveDown", pNode.idNodo, idPlaylistFile);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
      }
    });

  }



  function handleDelete(idPlaylistFile) {
    setIdToDelete(idPlaylistFile);
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);

    apiGetAvpPlaylistRemovePlaylistContent(idToDelete, function(response) {
      if (response!=null && response.status>=200 && response.status<400) {
        if (pActionCallback!=null) {
          pActionCallback("playlistDelete", pNode.idNodo, idToDelete);
        }
        else {
          console.warn("pActionCallback is null", pActionCallback);
        }
      }
    });
       
  };


  function handleEdit(idPlaylistFile) {
    navigate("/avp/grafo/nodo/playlistfile?action=edit&idavp="+pAvp.idAvp+"&idnodo="+pNode.idNodo+"&idplaylistfile="+idPlaylistFile);
  }

  function handleNew() {
    let idPlaylist = 0;
    navigate("/contenuti/pick?idavp="+pAvp.idAvp+"&idnodo="+pNode.idNodo+"&idlingua="+idLingua+"&action=addtoplaylist&idplaylist="+playlist.idPlaylist);
  }

  return (
    <div id="avp-gestioneavp-nodearcs-section"  >
        <div className="group">
          <div className="avp-gestioneavp-nodearcs-section-title" style={{"position":"relative", "float":"left"}}>
            Playlist
          </div>
          <div style={{"position":"relative", "float":"right"}}>
            <button style={{"height":"35px"}} onClick={handleNew}  >NUOVO FILE</button>
          </div>
        </div>

        <div className="avp-gestioneavp-nodearcs-section-box">
          <DataGrid
            sx={{ 
              m: 1,
              '& .MuiDataGrid-root': {
                margin: 0,
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
              },
              '& .MuiDataGrid-cell': {
                fontSize: 13,
              }, 
              '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              '& .MuiDataGrid-filler': {
                display: 'none',
              }
            }} 
            rowHeight={40}
            disableColumnMenu
            cellSelection={false}
            getRowId={(row) => row.idPlaylistFile}
            rows={rows}
            columns={columns}
            getRowClassName={(params) =>
              'tableRow'
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10,20]}
            disableRowSelectionOnClick
          />
        </div>

        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Conferma eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Eliminare il file selezionato?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} >NO</Button>
          <Button onClick={handleDeleteDialogConfirm} autoFocus>SI</Button>
        </DialogActions>
      </Dialog>
    </div> 
    
    
  ); 

} 